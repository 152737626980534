import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Cookies } from 'react-cookie';



export const getroledata = createAsyncThunk("getroledata", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "role/roles-list-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch 111==>", respdata);

    return respdata;

    // console.log("respdata.res", respdata.results.res);


})
export const getfaqcategorydata = createAsyncThunk("getfaqcategorydata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-faq-category/faq-category-list-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


const FaqReducer = createSlice({
    name: 'FaqReducer',
    initialState: {
        roledata: [],
        faqcategorydata: [],

    },

    reducers: {
        

        clearroledata(state, action) {
            state.roledata = []
        },
        clearfaqcategorydata(state, action) {
            state.faqcategorydata = []
        },
        
    },
    extraReducers: {

        


        [getroledata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getroledata.pending]: (state, action) => {
            state.loading = true;
        },
        [getroledata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.roledata = action.payload.results.res;
                state.loading = false;
            }


        },
        [getfaqcategorydata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getfaqcategorydata.pending]: (state, action) => {
            state.loading = true;
        },
        [getfaqcategorydata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.faqcategorydata = action.payload.results.res;
                state.loading = false;
            }


        },

    }

})

export default FaqReducer.reducer;
export const { clearroledata, clearfaqcategorydata } = FaqReducer.actions