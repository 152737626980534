import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const getEmailSlagData = createAsyncThunk("emailslagListData", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
              },
              "searchcondition": {},
              "project": {},
              "token": ""
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "email-slug/email-slug-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})



export const emailSlugEditdata = createAsyncThunk("emailSlugEditdata", async (id) => {
    let reqBody = {
        "condition": {},
        "sort": {

        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "email-slug/email-slug-list-count", requestOptions);
    const respdata = await response.json();
    return respdata;


})






export const getEmailTeamplatedata = createAsyncThunk("emailtemplateListData", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
              },
              "searchcondition": {},
              "project": {},
              "token": ""
        }
    }

    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "email-template/email-templates-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

export const emailtemplateEditdata = createAsyncThunk("emailtemplateEditdata", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 1,
            "skip": 0
          },
          "sort": {
            "field": "",
            "type": "desc"
          },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "email-template/email-templates-list-count", requestOptions);
    const respdata = await response.json();
    return respdata;


})

export const fetchCollectName = createAsyncThunk("fetchCollectName", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 1,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "email-slug/fetch-db-collections", requestOptions);
    const respdata = await response.json();
    return respdata;


})


const EmailTemplateReducer = createSlice({
    name: 'EmailTemplateReducer',
    initialState: {
        submitting: false,
        loading: false,
        editloading: false,
        emailtemplateSlagData: [],
        editemailtemplateSlagData: [],
        emailtemplateListData: [],
        editemailtemplateData: [],
        collectionName:[]


    },
    reducers: {
        clearEmailSlagedata(state, action) {
            state.emailtemplateSlagData = []
            state.emailtemplateListData=[]
        },
        clearEmailSlugEditdata(state, action) {
            state.editemailtemplateSlagData = []
        },
        clearEmailTemplatedata(state, action) {
            state.emailtemplateListData = []
        },
        clearEmailTemplateEditdata(state, action) {
            state.editemailtemplateData = []
        },
    },

    extraReducers: {
        /// #################### Training Listing ###############################
        [getEmailTeamplatedata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;

        },
        [getEmailTeamplatedata.pending]: (state, action) => {


            console.log("getContractListData=========================>")
            state.loading = true;

        },
        [getEmailTeamplatedata.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.emailtemplateListData = action.payload.results.res;
                state.loading = false;

            }

        },
        [emailtemplateEditdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.editloading = false;
            state.successAction = false;

        },
        [emailtemplateEditdata.pending]: (state, action) => {
            console.log("emailtemplateEditdata=========================>")
            state.editloading = true;

        },
        [emailtemplateEditdata.fulfilled]: (state, action) => {
            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.editemailtemplateData = action.payload.results.res;
                state.editloading = false;

            }

        },
        /// #################### Training Listing ###############################
        [getEmailSlagData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;

        },
        [getEmailSlagData.pending]: (state, action) => {


            console.log("getEmailSlagData=========================>")
            state.loading = true;

        },
        [getEmailSlagData.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.emailtemplateSlagData = action.payload.results.res;
                state.loading = false;

            }

        },




        [emailSlugEditdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },
        [emailSlugEditdata.pending]: (state, action) => {


            console.log("emailSlugEditdata=========================>")
            state.editloading = true;

        },
        [emailSlugEditdata.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.editemailtemplateSlagData = action.payload.results.res;
                state.editloading = false;

            }

        },



        [fetchCollectName.rejected]: (state, action) => {
         
        },
        [fetchCollectName.pending]: (state, action) => {
    

        },
        [fetchCollectName.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.collectionName = action.payload.results.res;
                

            }

        },
    }

})

export default EmailTemplateReducer.reducer;
export const { clearEmailTemplatedata, clearEmailTemplateEditdata, clearEmailSlugEditdata, clearEmailSlagedata } = EmailTemplateReducer.actions
