import { Paper, TableContainer } from '@mui/material';
import React, { useRef } from 'react'
import ListingTable from '../../../listing/listing';

function LeadAppointment({listData}) {
   
  var modifyTableHeaders = [
    { val: "fullname", name: "Name" },
    { val: "attendee", name: "Attendee" },
    { val: "organizer_name", name: "Organizer Name" },
    { val: "organizer_email", name: "Organizer Email" },  
];


let tableDataDashboard = useRef({
    tableId: "dashboardTable",
    tableTitle: "Lead Appointment List View",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "",
        deleteSingleUserEndpoint: "",
        tableCountEndpoint: "",
    },

    reqBody: {
        "condition": {
            "limit": 5,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": {},
        "project": {},
        "token": "",
        "count": false
    },
    deleteSingleUserReqBody: {
        source: "users",
        secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
        source: "users",
        secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [

        {
            id: "delete",
            label: "Delete",
            type: "api",
            category: "modal",
            icon_type: "delete",
            className: "delete_data",
            modalClassName: "delete_modal",
            modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
            btn_label: ['Yes', "No"],
            api_data: {
                base_url: process.env.REACT_APP_API_URL,
                endpoint: "contract-manage/contract-delete",
                body: {
                    "source": "users",
                    "secret": "AZ|lepL`",
                    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                }
            }
        },
        {
            id: "status",
            label: "Change Status",
            type: "api",
            category: "status",
            icon_type: "toggle_off",
            className: "status_data",
            modalClassName: "delete_modal",
            modalHeader: "<p> Alert !!</p>",
            modalTitle: "<p>Are you sure you want to change the status ?</p>",
            btn_label: ['Active', "Inactive"],
            api_data: {
                base_url: process.env.REACT_APP_API_URL,
                endpoint: "contract-manage/contract-status-update",
                body: {
                    "source": "users",
                    "secret": "AZ|lepL`",
                    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                }
            }
        },

    ],
    buttons: [
       
        {
            id: "summarize_icon",
            label: "Notes",
            type: "other",
            name: "summarize_icon",
            title: "Notes",
        },
        {
            id: "folder_icon",
            label: "Folder View",
            type: "other",
            name: "folder_icon",
            title: "Folder View",
        },
        {
            id: "assessment_icon",
            label: "Training Report",
            type: "other",
            name: "assessment_icon",
            title: "Training Report",
        },
        {
            id: "streetview_icon",
            label: "Leads",
            type: "other",
            name: "streetview_icon",
            title: "Leads",
        }



    ],
    deleteModal: {
        modalClassName: "modalblock",
    },

    searchData: {
        heading: "Search Bar",
        type: "drawer",
        formData: {
          id: 'searchForm',
          formWrapperClass: "formWrapperClass",
          formInlineCLass: "userForm", 
          formButtonClass: "submitbtnsection",
          submitBtnEndIcon: "search",
          resetBtnEndIcon: "replay",
          resetForm: true,
          formAdditionalSubmissionData: {},
          fields: [
            {
              id: 1,
              label: "Search By Name",
              name: "name",
              type: "autoComplete",
              className: 'list_search_item countDiv__column--col2 ',
              base_url: process.env.REACT_APP_API_URL + "",
              reqBody: {
        
                "condition": {
                  "limit": 5,
                  "skip": 0
                },
                "sort": {
                  "type": "asc",
                  "field": "name"
                },
                "searchcondition": {
  
                }
              },
              sx: { m: 1, minWidth: 300 },
              // payloadFormat: { key: "_id", param: "$regex", options: "i" },
              payloadFormat: { key: "name" },
  
            },
         
            {
              id: 2,
              label: "Search By Attendee ",
              name: "data_base_variable",
              type: "autoComplete",
              className: 'list_search_item countDiv__column--col2 ',
              base_url: process.env.REACT_APP_API_URL + "",
              reqBody: {
                // "formId": 'teamsearch',
                "condition": {
                  "limit": 5,
                  "skip": 0
                },
                "sort": {
                  "type": "asc",
                  "field": "name"
                },
                "searchcondition": {
  
                }
              },
              sx: { m: 1, minWidth: 300 },
              payloadFormat: { key: "data_base_variable" },
  
            },


            {
                id: 1,
                label: "Search By Organizer Name",
                name: "name",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col6 ',
                base_url: process.env.REACT_APP_API_URL + "",
                reqBody: {
                  // "formId": 'teamsearch',
                  "condition": {
                    "limit": 5,
                    "skip": 0
                  },
                  "sort": {
                    "type": "asc",
                    "field": "name"
                  },
                  "searchcondition": {
    
                  }
                },
                sx: { m: 1, minWidth: 300 },
                // payloadFormat: { key: "_id", param: "$regex", options: "i" },
                payloadFormat: { key: "name" },
    
              },


              {
                id: 2,
                label: "Search By Organizer Email ",
                name: "data_base_variable",
                type: "autoComplete",
                className: 'list_search_item countDiv__column--col6 ',
                base_url: process.env.REACT_APP_API_URL + "",
                reqBody: {
                  // "formId": 'teamsearch',
                  "condition": {
                    "limit": 5,
                    "skip": 0
                  },
                  "sort": {
                    "type": "asc",
                    "field": "name"
                  },
                  "searchcondition": {
    
                  }
                },
                sx: { m: 1, minWidth: 300 },
                payloadFormat: { key: "data_base_variable" },
    
              },
  
           
           
          ]
        }
      },
});

  return (
    <>

<TableContainer component={Paper} >
               

               <ListingTable    
                   tableData={
                       tableDataDashboard.current
                   }
                   dataset={listData}
                   modifyHeaders={modifyTableHeaders}
               />



        
       </TableContainer>

    </>
  )
}

export default LeadAppointment
