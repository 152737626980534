import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { CanvasJSChart } from "canvasjs-react-charts";
import { useCookies } from "react-cookie";
import ListingTable from "../../../listing/listing";
import { inputExternalPlugin } from "../../../helper/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAdminDashboardData,
  clearAdminDashboardUserListData,
  getDataFoAdminDashboardCard,
  getDataFoAdminDashboardChart,
  getDataFoAdminDashboardGridList,
  setMinMaxTime,
} from "./DashboardReducer";
import store from "../../../store";
import moment from "moment";
import SummarizeIcon from "@mui/icons-material/Summarize";
import FolderIcon from "@mui/icons-material/Folder";
import AssessmentIcon from "@mui/icons-material/Assessment";
import StreetviewIcon from "@mui/icons-material/Streetview";
import CloseIcon from "@mui/icons-material/Close";
import DiscoveryCallwithLead from "./DiscoveryCallwithLead";

// import TextField from '@mui/material/TextField';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AccessREP from "./AccessREP/AccessREP";
import FollowUpCalladded from "./FollowUpCalladded";
import AccessLaed from "./AccessLaed";
import ConversionCreate from "./ConversionCreate";
import OpportunityCreated from "./OpportunityCreated";

import {
  getAdminDashbordListData,
  getOpportunuitylistData,
  getAppointmentBookingListData,
  getAccessedRepData,
  cleardashbordListdata,
  clearGridListName,
  getAdminChartData,
} from "./DashbordListReducer";
import LeadAppointment from "./LeadAppointment";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import CompletedTrainingList from "./CompletedTrainingList";
import EmailSentList from "./EmailSentList";
import InformationLinkSentList from "./InformationLinkSentList";
import { resolvefunction } from "../../../helper/helperFunctions";
import { folderViewVisitLog } from "../userManagement/folderView/folderViewReducer";
import DNotes from "./DNotes";
import DiscoveryCallwithREPList from "./DiscoveryCallwithREPList";
import AllActivitiesList from "./AllActivitiesList";
// import CompletedTrainingList from "./CompletedTrainingList";

export default function Dashboard() {
  const scrollToGridData = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);
  const [isScriptTag, setIsScriptTag] = useState(false);
  const resolved = useSelector((state) =>
    state.ResolveReducer.resolved["/dashboard"]
      ? state.ResolveReducer.resolved["/dashboard"]
      : false
  );

  const [value, setValue] = useState([null, null]);
  const [scroll, setScroll] = useState(false);
  const [selectedBlock, setselectedBlock] = useState("Users Created");

  let userID = useSelector((state) =>
    state.loginSlice.userInfo ? state.loginSlice.userInfo?._id : null
  );
  let lastloginTime = useSelector((state) => state.loginSlice.last_login_time);

  let bttnClicked = useSelector(
    (state) =>
      state.tableSlice.customButtonClicked?.dashboardTable?.summarize_icon ||
      state.tableSlice.customButtonClicked?.dashboardTable?.assessment_icon ===
        true
  );

  let mindate = useSelector((state) =>
    state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {}
  );
  let maxDate = useSelector((state) =>
    state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {}
  );

  let adminCardData = useSelector((state) =>
    state.DashboardReducer?.adminCardData
      ? state.DashboardReducer.adminCardData
      : {}
  );
  let adminChartData = useSelector((state) =>
    state.DashboardReducer?.adminChartData
      ? state.DashboardReducer.adminChartData
      : {}
  );
  let adminGridData = useSelector((state) =>
    state.tableSlice?.otherResData?.dashboardTable?.grid_data
      ? state.tableSlice?.otherResData?.dashboardTable?.grid_data
      : state.DashboardReducer?.adminGridData
      ? state.DashboardReducer.adminGridData
      : {}
  );

  let adminListData = useSelector((state) =>
    state.DashboardReducer?.adminListData
      ? state.DashboardReducer.adminListData
      : null
  );

  const [openMoadl, setOpenMoadl] = React.useState(false);
  const [openMoadlforNotes, setOpenMoadlforNotes] = React.useState(false);
  const loaderForAdminData = useSelector((state) =>
    state.DashboardReducer?.loaderForAdminData
      ? state.DashboardReducer.loaderForAdminData
      : false
  );

  // <---------------------------------listData----------------------->
  const listType = useSelector((state) =>
    state.DashboardListReducer?.listType
      ? state.DashboardListReducer.listType
      : null
  );
  const listData = useSelector((state) =>
    state.DashboardListReducer?.ListData
      ? state.DashboardListReducer.ListData
      : []
  );
  const gridData = useSelector((state) =>
    state.DashboardListReducer?.gridData
      ? state.DashboardListReducer.gridData
      : null
  );
  const gridListName = useSelector((state) =>
    state.DashboardListReducer?.gridListName
      ? state.DashboardListReducer.gridListName
      : null
  );
  const loader = useSelector((state) =>
    state.DashboardListReducer?.loader
      ? state.DashboardListReducer.loader
      : false
  );
  const regulerChartData = useSelector((state) =>
    state.DashboardListReducer?.chartData
      ? state.DashboardListReducer.chartData
      : []
  );

  const folderViewBtn = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.dashboardTable &&
    state.tableSlice?.customButtonClicked?.dashboardTable?.folder_icon
      ? state.tableSlice?.customButtonClicked?.dashboardTable?.folder_icon
      : false
  );
  const leadViewBtn = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.dashboardTable &&
    state.tableSlice?.customButtonClicked?.dashboardTable?.streetview_icon
      ? state.tableSlice?.customButtonClicked?.dashboardTable?.streetview_icon
      : false
  );

  const dataRef = useSelector((state) =>
    state.tableSlice?.tempRowData &&
    state.tableSlice?.tempRowData?.dashboardTable
      ? state.tableSlice?.tempRowData?.dashboardTable
      : {}
  );

  let reqbody = {
    condition: {
      limit: 10,
      skip: 0,
    },
    sort: {
      field: "createdon_datetime",
      type: "desc",
    },
    searchcondition: {
      _id: userID,
      createdon_datetime: {
        $gte: mindate,
        $lte: maxDate,
      },
    },
    project: {},
    token: "",
    count: false,
  };

  useEffect(() => {
    if (!isScriptTag) {
      inputExternalPlugin(
        "dashboard",
        "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js"
      );
      setIsScriptTag(true);
    }
  }, [isScriptTag]);

  {
    /****************************************list section using react reuse******************************************** */
  }

  var modifyTableHeaders = [
    { val: "fullname", name: "Name" },
    { val: "email", name: "Email" },
    { val: "phone", name: "Phone" },
    // { val: "addedon", name: "Added ON" },
    {
      val: "createdon_datetime",
      name: "Added On",
      type: "datetime",
      format: "DD/MM/YYYY",
    },
  ];

  let tableDataDashboard = useRef({
    tableId: "dashboardTable",
    tableTitle: "User List View",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "users/admin-dashboard-grid-list",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "users/admin-dashboard-grid-list",
    },

    reqBody: {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        _id: userID,
        createdon_datetime: {
          $gte: mindate,
          $lte: maxDate,
        },
      },
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: ["fullname", "email", "createdon_datetime", "updatedon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "contract-manage/contract-delete",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Change Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "contract-manage/contract-status-update",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    buttons: [
      //  ########################### Notes ########################################
      {
        id: "notesBadge", // for unique id of button//
        label: "Notes", //on hover show//
        type: "note", //****button category */
        icon_type: "summarize_icon", //for the button icon from mui icons  https://mui.com/material-ui/material-icons///
        name: "note_inbuilt", //as your wish///
        className: "forminline", //for modal top level tag class name//
        count_key: "notescount", //count of notes, view in badge, value should be in row data object//
        notes_for_key: "fullname", //modal title notes for dynamic data, value should be in row data object
        noteaddbaseurl: process.env.REACT_APP_API_URL, //for end point base path//
        noteaddendpoint: "note/add-note", //final path of end point//
        noteaddbody: {
          //default body to add notes//
          data: {
            notes_by: userInfo?.userinfo?._id,
            collection_name: "users",
          },
        },
        body: {
          source: "",
          condition: { limit: 5, skip: 0 },
          sort: { type: "desc", field: "assigned_datetime" },
          searchcondition: {},
        }, //for initial listing end point call body//
        tableData: {
          //config for notes table in side modal like normal table config//
          tableId: "notesTable",
          tableTitle: " Notes Table",
          no_data_html: "<p>No Data Found </p>",
          payload_key: "notes_for",
          showReload: true,
          showFilter: true,
          api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "note/note-list-count",
            deleteSingleUserEndpoint: "note/delete-notes",
            tableCountEndpoint: "note/note-list-count",
          },
          reqBody: {
            condition: {
              limit: 5,
              skip: 0,
            },
            searchcondition: {},
            sort: {
              type: "desc",
              field: "createdon_datetime",
            },
          },
          deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
          rowsPerPageOptions: [5, 10, 20, 30],
          sortFields: ["notesby_names", "createdon_datetime", "notes"],
          paginationType: "stackPagination", ////////////////////// By Default Rows per page option will be available////////////,
          customBtnHead: [],
          buttons: [
            // {
            //   id: "deletetable01",
            //   type: "delete",
            //   label: "Delete",
            //   name: "delete",
            //   condField: "notes_by",
            //   condVal: loggedUserId,
            // }

            {
              id: "delete",
              label: "Multi Delete",
              type: "api",
              category: "modal",
              condField: "notes_by",
              condVal: userInfo?.userinfo._id,
              icon_type: "delete",
              className: "delete_data",
              modalClassName: "delete_modal",
              modalHeader: "<p> Alert !!</p>",
              modalTitle:
                "<p>Are you sure you want to delete this record? This can't be undone.</p>",
              btn_label: ["Yes", "No"],
              api_data: {
                base_url: process.env.REACT_APP_API_URL,
                endpoint: "note/delete-notes",
                body: {
                  source: "users",
                  secret: "AZ|lepL`",
                  token:
                    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                },
              },
            },
          ],
          deleteModal: {
            modalClassName: "Delete Note",
          },

          searchBarData: {
            formWrapperClass: "backendTableWrapper", // Can be undefined default will be formCls
            formInlineCLass: "backendTableInline test_one", // Can be undefined default will be formInline
            formButtonClass: "submitbuttonwrapper test searchRefreshIconBtn",
            endPointBodyWrapper: "searchcondition",
            heading: "Search Notes",
            class: "draWerp",

            api: {
              url: process.env.REACT_APP_API_URL,
              endPoint: "note/note-list-count",
              tableCountEndpoint: "note/note-list-count",
              reqBody: {
                source: "",
                condition: {
                  limit: 5,
                  skip: 0,
                },
                sort: {
                  type: "desc",
                  field: "_id",
                },
                searchcondition: {
                  notes_for: ""?._id,
                },
              },
            },
            searchSettings: {
              datesearch: [
                {
                  id: 0,
                  // heading: "Search By Joining Date",
                  startdatelabel: "Start Date",
                  enddatelabel: "End Date",
                  fullDay: true,
                  className: "inputblock4line",
                  field: "createdon_datetime",
                },
              ],

              textsearch: [
                // {
                //     id: 1,
                //     //   heading: "Search By Note",
                //     label: "Search By Note",
                //     field: "notes",
                //     className: "inputblock4line",
                //     value: "",
                // },
                // {
                //     id: 2,
                //     // heading: "Search Added By",
                //     label: "Search Added By",
                //     field: "added_name",
                //     className: "inputblock4line",
                //     value: "",
                // }
              ],
              autoCompleteSearch: [
                {
                  id: 6,
                  label: "Search Notes By ",
                  field: "notes_for",
                  values: "",
                  multiple: false,
                  preload: true,
                  className: "inputblock4line",
                  serverSearchData: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "",
                    reqBody: {
                      condition: {
                        limit: 10,
                        skip: 0,
                        notes_for: ""?._id,
                      },
                      sort: {
                        type: "desc",
                        field: "_id",
                      },
                      searchcondition: { notes_for: ""?._id },
                    },
                  },
                },
              ],

              selectsearch: [],
            },
          },
        },
        headers: [
          { val: "notes", name: "Note" },
          { val: "added_name", name: "Added By" },

          {
            val: "createdon_datetime",
            name: "Added On",
            type: "datetime",
            format: "MM/DD/YYYY hh:mm:ss",
          },
        ],
      },
      {
        id: "folder_icon",
        label: "Folder View",
        type: "other",
        name: "folder_icon",
        title: "Folder View",
      },
      {
        id: "assessment_icon",
        label: "Training Report",
        type: "other",
        name: "assessment_icon",
        title: "Training Report",
      },
      {
        id: "streetview_icon",
        label: "Leads",
        type: "other",
        name: "streetview_icon",
        title: "Leads",
      },
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "User List Search",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Name",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              "condition": {
                  "limit": 10,
                  "skip": 0
              },
              "sort": {
                  "type": "asc",
                  "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
            
              },
              "type": "user_name",
              "source": "users"
          },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 1,
            label: "Search By Email",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              "condition": {
                  "limit": 10,
                  "skip": 0
              },
              "sort": {
                  "type": "asc",
                  "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
            
              },
              "type": "user_email",
              "source": "users"
          },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 2,
            label: "Search By State",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              "condition": {
                  "limit": 10,
                  "skip": 0
              },
              "sort": {
                  "type": "asc",
                  "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
            
              },
              "type": "user_state",
              "source": "users"
          },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 3,
            label: "Search By City",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              "condition": {
                  "limit": 10,
                  "skip": 0
              },
              "sort": {
                  "type": "asc",
                  "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
            
              },
              "type": "user_city",
              "source": "users"
          },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 4,
            label: "Search By Zip",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              "condition": {
                  "limit": 10,
                  "skip": 0
              },
              "sort": {
                  "type": "asc",
                  "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
            
              },
              "type": "user_zip",
              "source": "users"
          },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 5,
            label: "Search By Address",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              "condition": {
                  "limit": 10,
                  "skip": 0
              },
              "sort": {
                  "type": "asc",
                  "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
            
              },
              "type": "user_address",
              "source": "users"
          },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 6,
            label: "Search By Stage",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              "condition": {
                  "limit": 10,
                  "skip": 0
              },
              "sort": {
                  "type": "asc",
                  "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
            
              },
              "type": "user_stage",
              "source": "users"
          },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 7,
            label: "Search By Tag",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/users-created",
            reqBody: {
              "condition": {
                  "limit": 10,
                  "skip": 0
              },
              "sort": {
                  "type": "asc",
                  "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $gte: mindate,
                  $lte: maxDate,
                },
            
              },
              "type": "user_tag",
              "source": "users"
          },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },
          },

          {
            id: 8,
            //   heading: "Search By Creation Time",
            label: "Search by Joining Start Date",
            name: "start_date",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by Joining End Date",
            name: "end_date",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (!resolved) {
      store.dispatch(getDataFoAdminDashboardCard(reqbody));
    }
  }, [resolved]);

  useEffect(() => {
    store.dispatch(getDataFoAdminDashboardGridList(reqbody));
    store.dispatch(getDataFoAdminDashboardChart(reqbody));

    return () => {
      store.dispatch(clearAdminDashboardData());
    };
  }, []);

  useEffect(() => {
    console.log("adminCardData", adminCardData);

    if (adminCardData && adminCardData.length > 0) {
      let stsrtTtime = moment(value[0]).valueOf();
      let endTtime = moment(value[1]).valueOf();
      // console.log("singleTestimonial ------------>", singleTestimonial);
      console.log(stsrtTtime, endTtime, "date");
      let domNodeArr = null;
      let reqbody = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "_id",
          type: "desc",
        },
        searchcondition: {
          // "_id": userID,
          createdon_datetime: {
            $gte: mindate,
            $lte: maxDate,
          },
        },
        project: {},
        token: "",
        count: false,
      };
      setTimeout(() => {
        domNodeArr = document.querySelectorAll("user-card ");
        console.log("domNodeArr", domNodeArr);
        if (domNodeArr && domNodeArr.length > 0) {
          for (let i = 0; i < domNodeArr.length; i++) {
            if (domNodeArr[i])
              domNodeArr[i].addEventListener("Summere-Card-Event", (e) => {
                let eventData = JSON.parse(
                  e.target.getAttribute("element-Event-Data")
                );
                let reqBody = { ...reqbody };
                switch (eventData) {
                  case "Users Created":
                    setselectedBlock("Users Created");
                    store.dispatch(cleardashbordListdata());
                    store.dispatch(clearGridListName());
                    store.dispatch(getDataFoAdminDashboardGridList(reqbody));
                    store.dispatch(getDataFoAdminDashboardChart(reqbody));

                    setScroll(true);

                    break;
                  case "Opportunity Created":
                    setselectedBlock("Opportunity Created");
                    store.dispatch(cleardashbordListdata());
                    store.dispatch(clearGridListName());
                    reqBody["source"] = "google_events";
                    reqBody["type"] = "opportunities";
                    store.dispatch(getAdminDashbordListData(reqBody));
                    store.dispatch(getAdminChartData(reqBody));
                    store.dispatch(clearAdminDashboardUserListData());
                    setScroll(true);
                    break;
                  case "Follow Up Call Added":
                    setselectedBlock("Follow Up Call Added");
                    store.dispatch(cleardashbordListdata());
                    store.dispatch(clearGridListName());
                    reqBody["source"] = "follow_up_calls";
                    reqBody["type"] = "follow_up_call";
                    store.dispatch(getAdminDashbordListData(reqBody));
                    store.dispatch(getAdminChartData(reqBody));
                    store.dispatch(clearAdminDashboardUserListData());
                    setScroll(true);
                    console.log("listData", listData);

                    break;
                  case "Conversion Created":
                    {
                      // setOpenMoadl(true);
                    }
                    break;
                  case "Training Completed":
                    setselectedBlock("Training Completed");
                    {
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      reqBody["source"] = "users";
                      reqBody["type"] = "training";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;
                  case "Appointment Booked":
                    setselectedBlock("Appointment Booked");
                    store.dispatch(cleardashbordListdata());
                    store.dispatch(clearGridListName());
                    reqBody["source"] = "google_events";
                    reqBody["type"] = "lead_appointment";
                    store.dispatch(getAdminDashbordListData(reqBody));
                    store.dispatch(getAdminChartData(reqBody));
                    store.dispatch(clearAdminDashboardUserListData());
                    setScroll(true);

                    break;
                  case "Accessed Lead Folder":
                    setselectedBlock("Accessed Lead Folder");
                    store.dispatch(cleardashbordListdata());
                    store.dispatch(clearGridListName());
                    reqBody["source"] = "folder_access_records";
                    reqBody["type"] = "folder_access_lead";
                    store.dispatch(getAdminDashbordListData(reqBody));
                    store.dispatch(getAdminChartData(reqBody));
                    store.dispatch(clearAdminDashboardUserListData());
                    setScroll(true);

                    break;
                  case "Accessed REP Folder":
                    {
                      setselectedBlock("Accessed REP Folder");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      console.log("rep working");
                      reqBody["source"] = "folder_access_records";
                      reqBody["type"] = "folder_access_rep";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;

                  case "Email Sent":
                    {
                      setselectedBlock("Email Sent");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      console.log("rep working");
                      reqBody["source"] = "assign_emails";
                      reqBody["type"] = "email_sent";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;

                  case "Information Link Sent":
                    {
                      setselectedBlock("Information Link Sent");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      reqBody["source"] = "assign_info_links";
                      reqBody["type"] = "info_link_sent";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;
                  case "Discovery Call with REP":
                    {
                      setselectedBlock("Discovery Call with REP");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      reqBody["source"] = "calendar_bookings";
                      reqBody["type"] = "discovery_call_rep";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;
                  case "Discovery Call with Lead":
                    {
                      setselectedBlock("Discovery Call with Lead");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      reqBody["source"] = "calendar_bookings";
                      reqBody["type"] = "discovery_call_lead";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;
                  case "All Activities":
                    {
                      setselectedBlock("All Activities");
                      store.dispatch(cleardashbordListdata());
                      store.dispatch(clearGridListName());
                      reqBody["source"] = "activity_list";
                      reqBody["type"] = "activity_deatails";
                      store.dispatch(getAdminDashbordListData(reqBody));
                      store.dispatch(getAdminChartData(reqBody));
                      store.dispatch(clearAdminDashboardUserListData());
                      setScroll(true);
                    }
                    break;
                  default:
                    {
                      setOpenMoadl(true);
                      console.log("Inside Default Case of switch-case");
                    }
                    break;
                }
              });
          }
        }
      }, 1000);
    }
  }, [adminCardData]);

  useEffect(() => {
    if (bttnClicked) {
      setOpenMoadl(true);
    }
  }, [bttnClicked]);

  const closeModal = () => {
    setOpenMoadl(false);
  };
  const openModal = () => {
    setOpenMoadl(true);
  };

  const closeModalForNotes = () => {
    setOpenMoadlforNotes(false);
  };

  const handelsearch = () => {
    let stsrtTtime = moment(value[0]).valueOf();
    let endTtime = moment(value[1]).valueOf();
    console.log("time", value, stsrtTtime, endTtime);

    let reqbody = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        _id: userID,
        createdon_datetime: {
          $gte: stsrtTtime,
          $lte: endTtime,
        },
      },
      project: {},
      token: "",
      count: false,
    };
    store.dispatch(clearAdminDashboardData());

    store.dispatch(getDataFoAdminDashboardCard(reqbody));
    store.dispatch(getDataFoAdminDashboardGridList(reqbody));
    store.dispatch(getDataFoAdminDashboardChart(reqbody));
    store.dispatch(setMinMaxTime(value));
  };

  useEffect(() => {
    if (folderViewBtn) {
      const payload = {
        user_for: dataRef._id,
        user_by: userInfo?.userinfo._id,
      };
      dispatch(folderViewVisitLog(payload));
      // resolvefunction(navigate, "/folderview?id=" + dataRef._id)
      window.open(`/folderview?id=${dataRef._id}`, "_blank");
    }
    console.log("dataRef", dataRef);
  }, [folderViewBtn]);

  useEffect(() => {
    if (leadViewBtn) {
      window.open(`/lead-list/${dataRef.main_role}/${dataRef._id}`, "_blank");
    }
  }, [leadViewBtn]);

  const handelNotesModal = (rowGridData) => {
    console.log("rowGridData", rowGridData);
  };

  // const handleDateChange = (event, value) => {
  // setStartDate(value.start);
  // setEndDate(value.end);
  // console.log("datecheck",startDate,endDate,event.target.value);

  // }

  // useEffect(()=>{
  //   let reqbody = {
  //     condition: {
  //       limit: 10,
  //       skip: 0,getAdminDashbordListData
  //     },
  //     sort: {
  //       field: "_id",
  //       type: "desc",
  //     },
  //     searchcondition: {
  //       // "_id": userID,

  //     },
  //     project: {},
  //     token: "",
  //     count: false,
  //   };
  //   let reqBody = {...reqbody}
  //   reqBody["type"] = "opportunities"
  //   reqBody["source"] = "google_events"

  //   console.log("requestbody-111111111-------->",reqBody)

  //   store.dispatch(getAdminDashbordListData(reqBody));
  // },[])
  // console.log("listData",listData);


  useEffect(() => {
    if (scroll === true) {
      scrollToGridData.current.scrollIntoView({ behavior: "smooth" });
    }

    setTimeout(() => {
      setScroll(false);
    }, 1000);
  }, [scroll]);
  return (
    <>
      <div>
        {(loaderForAdminData === true || loader) && <LinearProgress />}

        <h1 className="page_heading">Dashboard</h1>

        <Grid container spacing={1} className="dashboard_con">
          <Grid item md={12} xs={12}>
            <div className="dashboard_block1 dashboardCommon">
              <h2>
                <span>Welcome:</span>{" "}
                {userInfo.userinfo?.firstname +
                  " " +
                  userInfo.userinfo?.lastname}{" "}
              </h2>

              <h3>
                <span>Last Login:</span>{" "}
                {lastloginTime !== null
                  ? moment(Number(lastloginTime)).format(
                      "dddd, MMMM Do YYYY, h:mm a"
                    )
                  : "N/A"}
              </h3>
            </div>
          </Grid>
        </Grid>

        <div className="daterangepicker_one ">
          <span className="dash_brd_date">
            {" "}
            {moment(Number(mindate)).format("MMMM Do ")} -{" "}
            {moment(Number(maxDate)).format("MMMM Do , YYYY ")}
          </span>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={value}
              onChange={(newValue) => {
                console.log("newValue111", newValue);
                newValue.forEach((val) => console.log(moment(val).valueOf()));

                setValue(newValue);
              }}
              // onChange={handleDateChange}

              onOpen={() => {
                // clearErrors(fielddata.name);

                //////////////////////// Removing Missing License Key Message /////////////////////
                setTimeout(() => {
                  if (
                    document.querySelector("daterangepicker_one") !=
                      undefined &&
                    document.querySelector("daterangepicker_one").style !=
                      undefined
                  )
                    document.querySelector(
                      "daterangepicker_one"
                    ).style.display = "none";

                  let e = document.getElementsByTagName("div");
                  for (let i = 0; i < e.length; i++) {
                    let textval = e[i].innerText;
                    if (
                      textval.includes("Missing license key") &&
                      textval.length < 30
                    ) {
                      e[i].style.display = "none";
                    }
                  }
                  if (
                    document.querySelector("daterangepicker_one") !=
                      undefined &&
                    document.querySelector("daterangepicker_one").style !=
                      undefined
                  )
                    document.querySelector(
                      "daterangepicker_one"
                    ).style.display = "block";
                }, 200);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>

          <div className="daterangepicker_view_btn">
            <Button onClick={() => handelsearch()}>View</Button>
          </div>
        </div>

        <div className="dashboard_con2">
          {/* card tag  */}

          {adminCardData &&
            adminCardData?.map((item) => {
              return (
                <>
                  <div
                    className={
                      item.cardTitle == selectedBlock
                        ? "card_element dashboard_Icon_block dashboardCommon active"
                        : " card_element dashboard_Icon_block dashboardCommon inactive"
                    }
                  >
                    <user-card data={JSON.stringify(item)}></user-card>
                  </div>
                </>
              );
            })}
        </div>

        <div ref={scrollToGridData} className="dashboard_con3">
          <div className="dashboard_con3_sub CarouselView">
            {(loaderForAdminData === true || loader) && <LinearProgress />}
            <h2>
              {gridListName ? `${gridListName} Grid View` : "User Grid  View"}
              <Tooltip title={`${gridListName} Grid View`} placement="bottom">
                <QuestionMarkIcon className="gird_chart_tooltip" />
              </Tooltip>
            </h2>
            <Carousel showThumbs={false} autoPlay interval="5000" infiniteLoop>
              {gridData?.length > 0 ? (
                gridData.map((rowgrid) => (
                  <div className="divul">
                    <data-grid data={JSON.stringify(rowgrid)}></data-grid>

                    <Tooltip title="Notes">
                      <IconButton onClick={() => openModal()}>
                        <SummarizeIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Folder View">
                      <IconButton onClick={() => openModal()}>
                        <FolderIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Training Report">
                      <IconButton onClick={() => openModal()}>
                        <AssessmentIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Leads">
                      <IconButton onClick={() => openModal()}>
                        <StreetviewIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ))
              ) : adminGridData.length > 0 ? (
                adminGridData.map((rowgrid) => (
                  <div className="divul">
                    <data-grid data={JSON.stringify(rowgrid)}></data-grid>

                    <Tooltip title="Notes">
                      <IconButton>
                        <SummarizeIcon
                          onClick={() => setOpenMoadlforNotes(true)}
                          // onClick={() => openModal()}
                        />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Folder View">
                      <IconButton onClick={() => openModal()}>
                        <FolderIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Training Report">
                      <IconButton onClick={() => openModal()}>
                        <AssessmentIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Leads">
                      <IconButton onClick={() => openModal()}>
                        <StreetviewIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ))
              ) : (
                <div className="noDataAvailableGrid">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/Galveston/assets/images/no_result_image.png" />
                </div>
              )}
            </Carousel>

            <div></div>
          </div>

          <div className="dashboard_con3_sub">
            {(loaderForAdminData === true || loader) && <LinearProgress />}
            <h2>
              {gridListName ? `${gridListName} Chart View` : "User Chart View"}
              <Tooltip title={`${gridListName} Chart View`} placement="bottom">
                <QuestionMarkIcon className="gird_chart_tooltip" />
              </Tooltip>
            </h2>
            {Object.keys(adminChartData).length > 0 && (
              <line-chart data={JSON.stringify(adminChartData)}></line-chart>
            )}
            {Object.keys(regulerChartData).length > 0 && (
              <line-chart data={JSON.stringify(regulerChartData)}></line-chart>
            )}
          </div>
        </div>
        {(loaderForAdminData === true || loader) && <LinearProgress />}
        {adminListData && !loader && (
          <div className="dashboard_con3 dashboard_con3_listview">
            <TableContainer component={Paper}>
              <ListingTable
                tableData={tableDataDashboard.current}
                dataset={adminListData}
                modifyHeaders={modifyTableHeaders}
              />
            </TableContainer>
          </div>
        )}

        {/* 
          <div className="dashboard_con3 dashboard_con3_listview">
            <ConversionCreate />
          </div>
          */}

        {!(loaderForAdminData === true || loader) &&
          listType === "opportunities" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <OpportunityCreated listData={listData} />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "training" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <CompletedTrainingList listData={listData} />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "lead_appointment" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <LeadAppointment listData={listData} />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "folder_access_rep" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <AccessREP listData={listData} />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "follow_up_call" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <FollowUpCalladded listData={listData} />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "folder_access_lead" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <AccessLaed listData={listData} />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "email_sent" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <EmailSentList listData={listData} />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "info_link_sent" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <InformationLinkSentList listData={listData} />
            </div>
          )}

        {!(loaderForAdminData === true || loader) &&
          listType === "discovery_call_lead" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <DiscoveryCallwithLead listData={listData} />
            </div>
          )}
        {!(loaderForAdminData === true || loader) &&
          listType === "discovery_call_rep" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <DiscoveryCallwithREPList listData={listData} />
            </div>
          )}
        {!(loaderForAdminData === true || loader) &&
          listType === "activity_deatails" && (
            <div className="dashboard_con3 dashboard_con3_listview">
              <AllActivitiesList listData={listData} />
            </div>
          )}

        {(loaderForAdminData === true || loader) && <TableSkeleton count={5} />}
      </div>

      <div>
        <Modal
          classname="image_card_modal modalBaseStyle"
          open={openMoadl}
          onClose={closeModal}
          closeAfterTransition
        >
          <Box className="modalBaseBox largeModal">
            <IconButton className="modal_icon_button" onClick={closeModal}>
              <CloseIcon className="modal_close_icon" />
            </IconButton>
            <h1>Cooming Soon</h1>
          </Box>
        </Modal>
      </div>

      {openMoadlforNotes === true && (
        <DNotes
          // adminListData={adminListData}
          openMoadl={openMoadlforNotes}
          closeModal={closeModalForNotes}
        />
      )}
    </>
  );
}
