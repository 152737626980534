import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, IconButton, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, TextareaAutosize, TextField, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles, sendContract, signContract, upgradeRole } from '../../userManagement/userList/userListReducer';
import SnackBarComp from '../../../../commoncomponents/snackbar/snackBar';
import { ContractLogsInfo } from '../../userManagement/folderView/folderViewReducer';
import DownloadIcon from '@mui/icons-material/Download';


const ViewContractDetails = (props) => {

    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const { user_id, contract_id } = useParams(); //--- Fetch PathParams from URL ---

    // ---- States ----
    const [loading, setLoading] = useState(false);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
    const [snackFire, setSnackFire] = useState(false);  //--- Open SnackBar ----//
    const [resMessage, setResMessage] = useState(null);  //--- resMessage  ----//
    const [endpointResStatus, setEndpointResStatus] = useState(null);  //--- resMessage  ----//
    const [signature, setSignature] = useState('');  //--- resMessage  ----//
    const [contractSendSucess, setContractSendSucess] = useState(false);  //--- contractSendSucess  ----//

    console.warn("<<<<<< cookies goes here >>>>>", userInfo)
    console.warn("<<<<<< user_id from param here >>>>>", user_id)
    console.warn("<<<<<< contract_id from param here >>>>>", contract_id)


    // ---- for loader -----
    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)
    const contractSend = useSelector(state => (state.userManagment?.contractSend) ? state.userManagment?.contractSend : false)
    const contractSigned = useSelector(state => (state.userManagment?.contractSigned) ? state.userManagment?.contractSigned : false)
    const contractDetails = useSelector(state => (state.userFolderViewReducer?.ContractLogsData[0]) ? state.userFolderViewReducer?.ContractLogsData[0] : {})

    console.warn("<<<<<<< Contract Details goes here >>>>>>", contractDetails)


    useEffect(() => {
     if(contractSigned){
        setTimeout(() => {
            navigate('/contract-success');
        }, 3000);
     }
    }, [contractSigned])
    

    useEffect(() => {
        setContractSendSucess(false)
        // props?.tooglefunc(true);

        if (user_id !== undefined && user_id !== null && user_id !== 'undefined' && contract_id !== null && contract_id !== undefined && contract_id !== 'undefined') {
            const payload = {
                "condition": {},
                "sort": {
                    "field": "updatedon_datetime",
                    "type": "desc"
                },
                "searchcondition": {
                    "user_id": user_id,
                    "contract_id": contract_id
                },
                "project": {
                }
            }
            dispatch(ContractLogsInfo(payload))
        }


    }, [])


    useEffect(() => {
        if (contractSend) {
            setContractSendSucess(contractSend)
        }
    }, [contractSend])


    // ------ on contractSendSucess ---
    useEffect(() => {
        if (contractSendSucess) {
            setSnackFire(true)
            setResMessage("Contract Send Sucessfull!")
            setEndpointResStatus("success")

            setTimeout(() => {
                props?.tooglefunc(false);
            }, 3000);
        }
    }, [contractSendSucess])
    // ---------------------------------


    function sendContracts() {
        console.warn("<<<<<<<<<< sendContract >>>>>>>>>")

        const payload = {
            "data": {

                "user_id": user_id,
                "contract_id": contract_id,
                "signature": signature

            }
        }

        if (user_id !== undefined && user_id !== null && user_id !== 'undefined' && contract_id !== null && contract_id !== undefined && contract_id !== 'undefined' && signature !== "" && signature !== null && signature !== undefined)

            dispatch(signContract(payload))


    }


    return (
        <>

            {/* ------ Confirmation Modal ------ */}



            <div className="BoxMui_modal other_BoxMui_modal view_contract_modal">

                <div className="heading">
                    <h2> Contact Details</h2>
                </div>

                {Object.keys(contractDetails).length > 0 ? <div className='cont-body' dangerouslySetInnerHTML={{ __html: contractDetails.contract_html }} /> : <div className='loaderBlock'> <Box className="loader"><LinearProgress /></Box> </div>}

                {/* </div> */}




                <div className="btn-grp">
                    <div className="signatureBlock">
                        <TextField id="outlined-basic"
                            name='signature'
                            onInput={e => setSignature(e.target.value)}
                            className='inputBlock sign'
                            type="text"
                            label="Signature"
                            variant="outlined"
                            placeholder="Signature Here"
                        // style={{resize: "vertical", overflow: 'auto', overflowY:'scroll'}}
                        // defaultValue={props?.data?.priority} 
                        />
                    </div>

                    <div className='signMod'>
                        <p className='signModHere'>
                            {signature}
                        </p>
                    </div>

                    <Button className='sendBtn' variant="contained" onClick={() => sendContracts()}> SIGN CONTRACT</Button>

                    <Tooltip title="Download Contract">
                        <Button className='DLBtn' variant="contained" onClick={() => window.print()}> <DownloadIcon /> </Button>
                    </Tooltip>




                </div>

            </div>





            {/* --------- SnackBar -------- */}
            {snackFire != false && resMessage !== null ? <SnackBarComp
                setSnackFire={setSnackFire}
                resMessage={resMessage}
                snackFire={snackFire}
                endpointResStatus={endpointResStatus}
            /> : ""}
            {/* ------------ */}

        </>
    )
}


export default ViewContractDetails;



