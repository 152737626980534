import { Alert, Avatar, Box, Button, FormControl, Grid, Icon, IconButton, InputAdornment, LinearProgress, Paper, Snackbar, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import { Controller, useForm } from 'react-hook-form';
import { Visibility, VisibilityOff, AccountCircle } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessageAfterLoggedIn, clearMessageIsLoggedIn, getipInfo, login, setIpinfo } from './loginReducer';
// import styles from './Login.module.css'
import { Link, useNavigate } from 'react-router-dom';
import store from '../../store';
import { resolvefunction, routeHelper } from '../../helper/helperFunctions';
import { getdataforusernext } from './loginReducer';
import { useCookies } from 'react-cookie';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
// import { getPatientDetails } from '../AdminDashboardBackend/adminDashboardReducer';
// store.dispatch(getipInfo());
import CloseIcon from '@mui/icons-material/Close';
import "../login/login.css"
import { getDataFoAdminDashboard, getDataFoAdminDashboardCard, getDataForHmDashboard, getDataForHmDashboardCard } from '../Backend/Dashboard/DashboardReducer';
import { getDataForRepDashboardCard } from '../Backend/RepDashboard/RepDashboardReducer';
import { getDataForAerDashboardCard } from '../Backend/AerDashboard/AerDashboardReducer';
import ReportAnIssueModal from './reportanissuemodal';
import { getDataForBddDashboardCard } from '../Backend/BDDdashboard/BDDdashBoardReducer';








function Login() {
  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [token, setUserTokenCookie] = useCookies(["token"]);
  const [isdisabled, setIsdisabled] = useCookies(false);
  // const [userType, setUserType] = useState()
  const [lastLoginTime, setLastLoginTimeCookie] = useCookies(["lastLoginTime"]);
  let userType = useSelector(state => (state.loginSlice.userInfo?.main_role) ? state.loginSlice.userInfo?.main_role : null);
  let userID = useSelector(state => (state.loginSlice.userInfo) ? state.loginSlice.userInfo?._id : null);
  //   let ipdetails = useSelector(state => state.loginSlice.ipInfo ? state.loginSlice.ipInfo : null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   /////////////////////////////// Compoenent States /////////////////////////////////////
  const [showPassword, setShowPassword] = useState(false)
  const [open, setOpen] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    required: 'This Field is Required',
    validate: "Please Enter Valid Email",
    pattern: "Invalid Email Address"
  })
  const validate = (value) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    const isOk = strongRegex.test(value);
    // console.log("isOk", isOk);
    if (!isOk) setWrongPassword(true);
    else setWrongPassword(false);
  };


  const handleClickShowPassword = () => setShowPassword(!showPassword);

  //   ////////////////////////////////// Redux States //////////////////////////////////////////////
  const isLoggedIn = useSelector(state => {
    if (state.loginSlice.isLoggedIn !== null) return state.loginSlice.isLoggedIn;
    else return null;
  })
  const message = useSelector(state => state.loginSlice?.message ? state.loginSlice.message : null)
  const loading = useSelector(state => state.loginSlice?.loading ? state.loginSlice.loading : false)
  const loading0 = useSelector(state => state.userDashboardKey?.loading ? state.userDashboardKey.loading : false)
  const ipdetails = useSelector((state) => state.loginSlice.ipInfo ? state.loginSlice.ipInfo : null);


  //   /////////////////////////////// Hook Form /////////////////////////////////////////////
  const { handleSubmit, control, watch, formState: { errors }, clearErrors, setValue } = useForm();


  let mindate = useSelector(state => (state.DashboardReducer?.mindate) ? state.DashboardReducer.mindate : {})
  let maxDate = useSelector(state => (state.DashboardReducer?.maxDate) ? state.DashboardReducer.maxDate : {})





  useEffect(() => {
    fetch("https://ipinfo.io/?format=json&token=9797c42b93078a")
      .then((results) => results.json())
      .then((data) => {
        console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
        dispatch(setIpinfo(data));
      });
  }, []);


  //   //////////////////////////////// Form Submission //////////////////////////////////////
  const onSubmit = data => {
    // data.login_data = ipdetails;
    data.login_time = Math.round(new Date().getTime());
    data.login_data = ipdetails
    console.log(data);
    dispatch(login(data));
  }

  //   ////////////////////////////////// Functions /////////////////////////////////////////
  //   const handleClickShowPassword = () => setShowPassword(!showPassword)
  const isValidPassword = (password) => password !== '' ? true : false;
  //   // const handleClose = () => setOpen(false);
  //   ///////////////////////////////store/////////////////////////////
  store.subscribe(() => {
    const storedata = store.getState();
    managenav(
      storedata,
      setuserInfoCookie,
      setUserTokenCookie,
      setLastLoginTimeCookie,
      navigate
    );

    // if(storedata.loginSlice.userInfo?.user_types[0]?.name)
    // if(storedata.loginSlice.userInfo!==null && storedata.loginSlice.userInfo!==undefined && storedata.loginSlice.userInfo?.user_types !==null && storedata.loginSlice.userInfo?.user_types !==undefined && storedata.loginSlice.userInfo?.user_types.length>0 ){
    //   setUserType(storedata.loginSlice.userInfo?.user_types[0]?.name)
    // }

    // if (storedata.loginSlice.userInfo !== null && storedata.loginSlice.userInfo !== undefined  && storedata.loginSlice.userInfo?.user_types !== null && storedata.loginSlice.userInfo?.user_types !== undefined && storedata.loginSlice.userInfo?.user_types.length > 0) {
    //   setUserType(storedata.loginSlice.userInfo?.user_types)
    // }








  });
  //   /////////////////////////////useeffect/////////////////////////////////////////

  // useEffect(() => {
  //     const subscription = watch((value, { name, type }) => {
  //       console.log(
  //         "value",
  //         value,
  //         "name",
  //         name,
  //         "type",
  //         type,
  //       )
  //       console.log("errors", errors)
  //     })
  //     return () => subscription.unsubscribe()
  //   }, [watch])
  // console.warn("<<<<<< cookies >>>>>>", userInfo)

  useEffect(() => {
    let reqBodyFordashboard = {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "_id",
        "type": "desc"
      },
      "searchcondition": {
        "_id": userID,
        "createdon_datetime": {
          "$gte": mindate,
          "$lte": maxDate
        }
      },
      "project": {},
      "token": "",
      "count": false
    }

    console.log("userType111111111112", userType);

    if (isLoggedIn !== null && isLoggedIn !== undefined && isLoggedIn === true) {
      setOpen(true);

      setTimeout(() => {

        console.log("userType11111111111", userType);
        switch (userType) {
          case "is_admin": { resolvefunction(navigate, '/dashboard', getDataFoAdminDashboardCard(reqBodyFordashboard)) }
            break;
          case "hr_manager": { resolvefunction(navigate, '/hm-dashboard', getDataForHmDashboardCard(reqBodyFordashboard)) }
            break;
          case "is_rep": { resolvefunction(navigate, '/rep-dashboard', getDataForRepDashboardCard(reqBodyFordashboard)) }
            break;
          case "is_aer": { resolvefunction(navigate, '/aer-dashboard', getDataForAerDashboardCard(reqBodyFordashboard)) }
            break;
          case "is_bdd": { resolvefunction(navigate, '/bdd-dashboard', getDataForBddDashboardCard(reqBodyFordashboard)) }
            break;

          default:
            resolvefunction(navigate, '/dashboard')
        }


        // resolvefunction(navigate, '/dashboard')





      }, 2000);


      // routeHelper({
      //   resolvePath: `${userType}/dashboard`,
      //   path: `/${userType}/dashboard`,
      //   // func: getPatientDetails,
      //   state: store.getState().loginSlice,
      //   navigate,
      //   body: reqBody,
      // });


    }

  }, [isLoggedIn])



  useEffect(() => {
    if (message !== null) {
      setOpen(true)
    }
    else {
      setOpen(true)
    }
  }, [message])




  useEffect(() => {
    return () => {
      setOpen(true)
      // dispatch(clearMessageAfterLoggedIn())
    }
  }, [])

  function onChnageAction() {
    console.warn("<<<<<<<< Report issue Modal Fire >>>>>");
    setReportModal(true);
    console.warn("<<<<<<<< Report issue Modal Fire >>>>>", reportModal);

  }



  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" >
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
      // onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  return (
    <div className='loginBody'>
      {(loading || loading0) && <LinearProgress />}



      <img className='logo' src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/logo.webp" />



      <div className='loginBox'>
        <div className='loginBox_body'>




          <h1>
            Login
          </h1>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              }}
              render={() => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  type="text"
                  autoFocus
                  onChange={(e) => {
                    setValue("email", e.target.value?.toLowerCase().trim());
                    clearErrors("email");
                    dispatch(clearMessageIsLoggedIn());
                  }}
                  error={
                    Object.keys(errors).length > 0 && errors.email != null
                  }
                  helperText={
                    errors.email && errorMessages[errors.email.type]
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" edge="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              rules={{ required: true, validate: isValidPassword }}
              render={() => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  error={
                    Object.keys(errors).length > 0 &&
                    errors.password != null
                  }
                  helperText={
                    errors.password && errorMessages[errors.password.type]
                  }
                  onChange={(e) => {
                    setValue("password", e.target.value);
                    clearErrors("password");
                    dispatch(clearMessageIsLoggedIn());
                    validate(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/* {wrongPassword && (
                <p className="loginerror">
                  {" "}
                  Password field must be One Number, One Capitalized,One
                  Special Cahracter & 8 Chracters
                </p>
              )} */}

            <div>
              {/* <Grid item xs /> */}

              <Link className="forgot_password" to="/forget-password">
                Forgot Password?
              </Link>

            </div>

            <Button
              className="passBttn"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              disabled={loading}
            >
              <span> Login </span>
            </Button>

          </form>
          {loading && <LinearProgress sx={{ margin: "5px" }} />}



        </div>
      </div>



      {/* ------------ ReportAnIssueModal ------ */}

      {reportModal==true ?
        <ReportAnIssueModal
          toogle={reportModal}
          tooglefunc={setReportModal}
        /> : ""}



      {message !== null ?
        (<Snackbar
          className=''
          open={open}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          message={message}
          action={action}
          onClick={onChnageAction}
        // sx={{ width: '100%' }}
        />)
        : ""}


    </div>
  )
}

export default Login

function managenav(
  tempstore,
  setuserInfoCookie,
  setUserTokenCookie,
  setLastLoginTimeCookie,
  navigate
) {
  if (
    tempstore?.loginSlice?.userInfo !== undefined &&
    tempstore?.loginSlice?.userInfo?._id !== null &&
    tempstore?.loginSlice?.userInfo?._id !== undefined
  ) {
    setuserInfoCookie(
      "userinfo",
      JSON.stringify(tempstore.loginSlice.userInfo),
      { path: "/" }
    );

    setUserTokenCookie("token", tempstore.loginSlice.token, { path: "/" });

    setLastLoginTimeCookie(
      "lastLoginTime",
      tempstore.loginSlice.last_login_time,
      { path: "/" }
    );
  }
}

