import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeField, setField, setOtherData } from '../../../../form/formReducer';
import { Button, Dialog, DialogContent, DialogTitle, Grid, LinearProgress } from '@mui/material';
import { useCookies } from "react-cookie";
import StateArray from '../../../../assets/json/state';
import CloseIcon from '@mui/icons-material/Close';
import { FetchRoles, fetchUserRolesName, getusersList, setStatesBlank } from '../userList/userListReducer';
import { Box } from '@mui/system';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import { resolvefunction } from '../../../../helper/helperFunctions';


const AddEditForm = (props) => {
  // const [users, setusers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const param = useParams();//---- userParam 

  // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

  // ---- States here -----
  const [loading, setLoading] = useState(true);  //*****FOR LODING****//
  const [userInfo, setUserInfo] = useState(null); // Set User Information from Cookie
  const [formSubmissionStateUserForm, setFormSubmissionStateUserForm] = useState(0) //**** FOR FORM SUBMIT ****//
  const [locationArry, setLocationArry] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [openExternalForm, setOpenExternalForm] = useState(false)
  const [filteredRoles, setFilteredRoles] = useState([])
  const [editRole, setEditRole] = useState([])

  const [feilds, setFeilds] = useState([])
  const [roleArray, setRoleArray] = useState([])

  // const [formDataSet, setFormDataSet] = useState(null)



  // ---- Cookies here ----
  const [cookieData, setCookieData] = useCookies();
  // console.warn("<<<<< cookieData from addEditFrom component>>>>>", cookieData)


  // ----- On assignRoleBtn Click ----
  const assignRoleBtn = useSelector(state => (state.formSlice?.formCustomButtonClicked?.userForm?.userRoles) ? state.formSlice.formCustomButtonClicked.userForm.userRoles : false);

  // ----- On goBackBtnClick  ----
  const goBackBtnClick = useSelector(state => (state.formSlice?.customButtonClicked?.userForm && state.formSlice?.customButtonClicked?.userForm?.goBack001) ? state.formSlice?.customButtonClicked?.userForm?.goBack001 : false);


  // ----- userRolesFetched From database  ----
  const userRoles = useSelector(state => (state.userManagment?.fetchRoles) ? state.userManagment?.fetchRoles : []);

  // ----- On goBackBtnClick  ----
  const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false);

  const externalFormSubmit = useSelector(state => (state.formSlice?.formSubmissionState?.externalForm) ? state.formSlice.formSubmissionState.externalForm : null);

  const userSelectedRole = useSelector(state => (state.formSlice?.formData?.userForm?.roles) ? state.formSlice.formData.userForm.roles : []);

  const formFeilds = useSelector(state => (state.formSlice?.formFieldsData?.userForm) ? state.formSlice.formData.userForm : undefined);

  // console.warn("<<<<<< props.fetchData >>>>", Object.keys(props?.fetchData?.roles))
  // console.warn("<<<<<< userRoles >>>>", userRoles)
  // console.warn("<<<<<< formFeilds >>>>", formFeilds)




  useEffect(() => {
    if (formFeilds !== undefined && formFeilds !== null) {
      setFeilds(formFeilds)
      // console.warn("<<<<<<< feilds >>>>>", feilds)
    }
  }, [formFeilds])

  useEffect(() => {
    console.log("props11123", roleArray, props?.fetchData?.roloesArr);


  }, [roleArray])


  useEffect(() => {

    console.log("props", props, props?.fetchData?.roloesArr);
    setRoleArray(props?.fetchData?.roloesArr)
    console.log("props111", roleArray, props?.fetchData?.roloesArr);
    // setRoleArray(['hr_manager'])

    // setTimeout(() => {
    //   console.log("props1112", roleArray, props?.fetchData?.roloesArr);

    // }, 2000);

  }, [])






  // --------- filtering user roles based on which kind of user loggedin ----------
  useEffect(() => {
    if (userRoles.length > 0) {
      console.warn("<<<<<<< cookieData >>>>>>", cookieData)
      console.warn("<<<<<<< userRoles >>>>>>", userRoles)

      if (cookieData?.userinfo?.roles?.is_admin == 1) {

        setFilteredRoles(userRoles)

      } else if (cookieData?.userinfo?.roles?.is_bdd == 1) {

        const Roles = userRoles.filter((val) => val.val == 'hr_manager')
        setFilteredRoles(Roles)

      } else if (cookieData?.userinfo?.roles?.hr_manager == 1 || cookieData?.userinfo?.roles?.is_aer == 1) {

        const Roles = userRoles.filter((val) => val.val == 'is_rep')
        setFilteredRoles(Roles)

        // console.warn("<<<<<<< filteredRoles 1 >>>>>>", filteredRoles)

      }

      // console.warn("<<<<<<< filteredRoles >>>>>>", filteredRoles)

    }
  }, [userRoles])
  // ---------------------------------------------------


  useEffect(() => {
    console.warn("<<<<<<< filteredRoles 2 >>>>>>", filteredRoles)
    if (filteredRoles.length > 0) {
      formData.current.fields[2].values = filteredRoles
    }
  }, [filteredRoles])



  // useEffect(() => {
  //   if (userRoles.length > 0) {
  //     // console.log("formData.current.fields[2].values", formData.current)
  //     formData.current.fields[2].values = userRoles
  //     // console.log("formData.current.fields[2].values >>> 2", formData.current)
  //   }
  // }, [userRoles])




  useEffect(() => {
    setOpenExternalForm(false)
    dispatch(setStatesBlank())

    if (cookieData && cookieData.userinfocookies) {
      setUserInfo(cookieData.userinfocookies);
    }
  }, []);


  useEffect(() => {
    const data = {
      "condition": {},
      "sort": {
        "field": "name",
        "type": "asc"
      },
      "searchcondition": {},
      "project": {
        "name": 1,
        "slug": 1,
        "status": 1,
      },
      "token": ""
    }

    dispatch(FetchRoles(data));
  }, [])





  // -------- State injecting -----------------------
  useEffect(() => {
    let Newarr = [];
    if (StateArray && StateArray.length > 0) {
      for (let i in StateArray) {
        Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
      }
      setLocationArry(Newarr);
    }

  }, [])

  useEffect(() => {
    if (locationArry.length > 0) {
      formData.current.fields[5].values = locationArry
    }
  }, [locationArry])

  //---------------------------------------




  // ------ gobackButton Click -------
  useEffect(() => {
    if (goBackBtnClick) {

      const reqBody= {

        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "createdon_datetime",
            "type": "desc"
        },
        "searchcondition": {
            "_id": userInfo?.userinfo?.roles?.is_admin !== 1 ? userInfo?.userinfo?._id : undefined, //-- list will show exculding 
        },

        "count": false,

    }

      setTimeout(() => {
        resolvefunction(navigate, "/usermanagement", getusersList(reqBody))
      }, 1000);

    }
  }, [goBackBtnClick])
  // ----------------------------




  let addForm = [
    {
      id: 0,
      heading: "First Name",
      label: "First Name",
      name: "firstname",
      className: 'inputBlock inputBlock4line',
      // defaultValue: props.fetchData?.firstname ? props.fetchData.firstname : '',

      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

    },


    {
      id: 1,
      heading: "Last Name",
      label: "Last Name",
      name: "lastname",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

    },

    {
      id: 2,
      heading: "User Type",
      label: "Select Roles",
      name: "roles",
      className: 'inputBlock usertypebox inputBlock4line',
      type: 'select',
      multiple: true,
      checkbox: true,
      // other: true,
      rules: { required: true },
      values: [],
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

      sx: { m: 1, minWidth: 120 },
    },


    {
      id: 3,
      heading: "Email",
      label: "Email",
      name: "email",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: '',
      value: '',
      inputType: "email",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
    },


    {
      id: 4,
      // heading: "First Name",
      label: "Address",
      name: "address",
      className: 'inputBlock addressbox inputBlock1line',
      type: "textarea",
      //inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

    },

    {
      id: 5,
      heading: "State",
      label: "Select State",
      name: "state",
      className: 'inputBlock usertypebox inputBlock4line',
      type: "select",
      other: false,
      values: [],
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

    },

    {
      id: 6,
      heading: "City",
      label: "City",
      name: "city",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

    },

    {
      id: 7,
      heading: "Zip",
      label: "Zip",
      name: "zip",
      className: 'inputBlock inputBlock4line',
      type: "text",
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },

    },


    {
      id: 8,
      heading: "Phone",
      label: "Phone",
      name: "phone",
      className: 'inputBlock inputBlock4line',
      type: "text",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },


    },


    {
      id: 9,
      heading: "Secondary Phone",
      label: "Secondary Phone",
      name: "secondary_phone",
      className: 'inputBlock inputBlock4line',
      type: "text",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////
    },

    {
      id: 10,
      heading: "Secondary Email",
      label: "Secondary Email",
      name: "secondary_email",
      className: 'inputBlock inputBlock4line',
      type: "text",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////

    },


    {
      id: 11,
      heading: "Password",
      label: "Password",
      name: "password",
      className: 'inputBlock inputBlock4line passwordinput',
      type: 'password',
      defaultValue: '',
      rules: { required: true, pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/ },
      errorMessage: {
        required: "This Field is Required",
        errorMessageConfirmPassword: "Password does not match",
        pattern: "Password contain at least one numeric digit and a special character",
        custom: "Value is Invalid"
      },
    },

    {
      id: 12,
      heading: "Confirm Password",
      label: "Confirm Password",
      name: "confirm_password",
      className: 'inputBlock inputBlock4line passwordinput',
      type: 'password',
      defaultValue: '',
      rules: { required: true, pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/ },
      errorMessage: {
        required: "This Field is Required",
        errorMessageConfirmPassword: "Password does not match",
        pattern: "Password contain at least one numeric digit and a special character",
        custom: "Value is Invalid" ////////////////////// Include this for confirm password field //////////
      },
    },

    {
      id: 13,
      // heading: "Status",
      label: "Status",
      name: "status",
      className: 'singleCheckbox inputBlock1line',
      type: 'singleCheckbox',
      values: { key: 1, val: 'Active' },
      // rules: { required: true, validate: isValidCheck },
      errorMessage: {
        required: "This Field is Required",
        validate: "is not valid",
        custom: "Value is Invalid"
      },
      defaultValue: 1
    },

  ]


  let editForm = [
    {
      id: 0,
      heading: "First Name",
      label: "First Name",
      name: "firstname",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.firstname ? props.fetchData.firstname : '',
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
    },


    {
      id: 1,
      heading: "Last Name",
      label: "Last Name",
      name: "lastname",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.lastname ? props.fetchData.lastname : '',
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },


    },


    {
      id: 2,
      heading: "User Type",
      label: "Select Roles",
      name: "roles",
      className: 'inputBlock usertypebox inputBlock4line',
      type: 'select',
      multiple: true,
      checkbox: true,
      // other: true,
      rules: { required: true },
      values: filteredRoles,
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
      defaultValue: props.fetchData?.roloesArr ? props.fetchData.roloesArr : '',
      sx: { m: 1, minWidth: 120 },
    },


    {
      id: 3,
      heading: "Email",
      label: "Email",
      name: "email",
      className: 'inputBlock inputBlock4line',
      type: "text",
      disabled: true,
      defaultValue: props.fetchData?.email ? props.fetchData.email : '',
      inputType: "email",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },


    },




    {
      id: 4,
      // heading: "First Name",
      label: "Address",
      name: "address",
      className: 'inputBlock addressbox inputBlock1line',
      type: "textarea",
      defaultValue: props.fetchData?.address ? props.fetchData.address : '',

      //inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },


    },

    {
      id: 5,
      heading: "State",
      label: "Select State",
      name: "state",
      className: 'inputBlock usertypebox inputBlock4line',
      type: "select",
      defaultValue: props.fetchData?.state ? props.fetchData.state : '',
      other: false,
      values: [],
      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },


    },

    {
      id: 6,
      heading: "City",
      label: "City",
      name: "city",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.city ? props.fetchData.city : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },


    },

    {
      id: 7,
      heading: "Zip",
      label: "Zip",
      name: "zip",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.zip ? props.fetchData.zip : '',

      // inputType: "number",/////////////// If not mentioned default will be text //////////////
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },


    },


    {
      id: 8,
      heading: "Phone",
      label: "Phone",
      name: "phone",
      className: 'inputBlock inputBlock4line',
      type: "text",
      inputType: "phone",/////////////// If not mentioned default will be text //////////////
      defaultValue: props.fetchData?.phone ? props.fetchData.phone : '',
      // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },


    },


    {
      id: 9,
      heading: "Secondary Email",
      label: "Secondary Email",
      name: "secondary_email",
      className: 'inputBlock inputBlock4line',
      type: "text",
      defaultValue: props.fetchData?.secondary_email ? props.fetchData.secondary_email : '',

    },


    {
      id: 10,
      heading: "Secondary Phone",
      label: "Secondary Phone",
      name: "secondary_phone",
      className: 'inputBlock inputBlock4line',
      type: "text",
      inputType: "phone",
      defaultValue: props.fetchData?.secondary_phone ? props.fetchData.secondary_phone : '',

    },

    {
      id: 11,
      // heading: "Status",
      label: "Status",
      name: "status",
      className: 'singleCheckbox inputBlock1line',
      type: 'singleCheckbox',
      values: { key: 1, val: 'Active' },
      // rules: { required: true, validate: isValidCheck },
      errorMessage: {
        required: "This Field is Required",
        validate: "is not valid",
        custom: "Value is Invalid"
      },
      defaultValue: props.fetchData?.status ? props.fetchData.status : '',
    },



  ]


  // ---- userCreateForm ------
  const formData = useRef({
    id: 'userForm',
    formtype: "add",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "users/add-update-user",
    endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/usermanagement",
    // msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "tblform",// Can be undefined default will be formCls
    formInlineCLass: "inlnfrm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    submitBtnName: props.queryParamId ? "UPDATE" : "SUBMIT",
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      // type: "admin",
      // status: "active",
      type: props.fetchData?.type ? props.fetchData.type : props.userType,
      added_by: cookieData?.userinfo?._id,
      _id: props?.queryParamId ? props.queryParamId : undefined
    },

    fields: props?.queryParamId ? editForm : addForm,

    customButtons: [
      {
        id: 'goBack001',
        label: 'Go Back',
        className: 'default',
        type: 'button',
      },

    ]

  })






  // ------ Push feild as per roll selection -----
  useEffect(() => {
    // console.log("<<<<<, userSelectedRolelog 1 >>>>>>>>>>>>", userSelectedRole, roleArray);

    if (userSelectedRole !== null && userSelectedRole?.length > 0 || roleArray?.length > 0) {
      // console.log("<<<<<, userSelectedRole 2 ....  >>>>>>>>>>>>", userSelectedRole);

      let selectedFilterdRoles = [];
      if (userSelectedRole !== null && userSelectedRole?.length > 0) selectedFilterdRoles = [...userSelectedRole]
      if (roleArray !== null && roleArray?.length > 0) selectedFilterdRoles = [...roleArray]

      // console.warn("<<<<<, selectedFilterdRoles >>>>>>>>>>>>", selectedFilterdRoles);


      setTimeout(() => {

        let associated_programm = {
          id: 20,
          heading: "Associated Programe",
          label: "Associated Programe",
          name: "associated_program",
          type: "autoComplete",
          className: "inputBlock inputBlock4line",
          multiple: true,
          base_url: process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list",

          reqBody: {
            "formId": 'searchtestimonial',
            "condition": {
              "limit": 10,
              "skip": 0
            },
            "sort": {
              "type": "desc",
              "field": "_id"
            },
            "searchcondition": {

            }
          },

          sx: { m: 1, minWidth: 300 },
          // defaultValue: props.fetchData?.associated_program ? props.fetchData?.associated_program : ''
          // payloadFormat: { key: "program" },

        }

        let hiring_manager = {
          id: 21,
          heading: "Hiring Manager",
          label: "Hiring Manager",
          name: "hiring_manager",
          type: "autoComplete",
          className: "inputBlock inputBlock4line",
          multiple: false,
          base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
          reqBody: {
            "formId": 'searchtestimonial',
            "condition": {
              "limit": 10,
              "skip": 0
            },
            "sort": {

            },
            "searchcondition": {
              "status": 1,
              'roles.hr_manager': 1
            },

          },
          sx: { m: 1, minWidth: 300 },
          // defaultValue: props.fetchData?.practice_id ? props.fetchData?.practice_id : ''

        }

        let associated_bdd = {
          id: 22,
          heading: "Choose BDD",
          label: "Choose Business Development Director",
          name: "associated_bdd",
          type: "autoComplete",
          className: "inputBlock inputBlock4line",
          multiple: false,
          base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
          reqBody: {
            "formId": 'searchtestimonial',
            "condition": {
              "limit": 10,
              "skip": 0
            },
            "sort": {

            },
            "searchcondition": {
              "status": 1,
              'roles.is_bdd': 1
            },


          },
          sx: { m: 1, minWidth: 300 },
          // defaultValue: props.fetchData?.practice_id ? props.fetchData?.practice_id : ''

        }

        // console.log("selectedFilterdRoles_Before", selectedFilterdRoles);


        if (selectedFilterdRoles?.includes('is_aer') || selectedFilterdRoles?.includes('is_rep') || selectedFilterdRoles?.includes('hr_manager') || selectedFilterdRoles?.includes('is_bdd')) {
          console.log("selectedFilterdRoles_After", selectedFilterdRoles);

          dispatch(setField({ formId: "userForm", after: "roles", fieldData: associated_programm }))


        } else {
          setTimeout(() => dispatch(removeField({ formId: "userForm", name: "associated_program" })), 500)

        }


        if (selectedFilterdRoles?.includes('is_rep')) {
          setTimeout(() => dispatch(setField({ formId: "userForm", after: "email", fieldData: hiring_manager })), 1000)
        }
        else {
          setTimeout(() => dispatch(removeField({ formId: "userForm", name: "hiring_manager" })), 1000);
        }


        if (selectedFilterdRoles?.includes('hr_manager')) {
          setTimeout(() => dispatch(setField({ formId: "userForm", after: "state", fieldData: associated_bdd })), 1500)
        }
        else {
          setTimeout(() => dispatch(removeField({ formId: "userForm", name: "associated_bdd" })), 1500);
        }

        console.log();

      }, 200);

    } else {
      feilds.hasOwnProperty('associated_program') && setTimeout(() => dispatch(removeField({ formId: "userForm", name: "associated_program" })), 500)

      feilds.hasOwnProperty('associated_bdd') && setTimeout(() => dispatch(removeField({ formId: "userForm", name: "associated_bdd" })), 1000)

      feilds.hasOwnProperty('hiring_manager') && setTimeout(() => dispatch(removeField({ formId: "userForm", name: "hiring_manager" })), 1500)
    }

  }, [JSON.stringify(userSelectedRole), JSON.stringify(roleArray)])
  // ----------------------------------------







  return (
    <>
      <div>

        <div className='adminform_wrapper'>

          {loader ? <Box sx={{ width: "100%" }}><LinearProgress /></Box> : null}


          {filteredRoles && filteredRoles.length > 0 ? <Form formData={formData.current} /> : ""}  {/* --- form here -- */}



        </div>

      </div>


    </>
  )
}

export default AddEditForm;