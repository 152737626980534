import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';
import ListingTable from '../../../listing/listing'
import { TableSkeleton } from '../../../helper/TableSkeleton'
import { useDispatch, useSelector } from 'react-redux';
import { getHmGroupListdata } from './HmGroupReducer';

export default function HmGroupList() {

    const fetchedgetHmGroupListdata = useSelector(state => (state.HmGroupReducer?.hmGroupListdata) ? state.HmGroupReducer.hmGroupListdata : [])

    const navigate = useNavigate();

    const loader = useSelector(state => (state.HmGroupReducer?.loading) ? state.HmGroupReducer.loading : false);

 

    

    // console.log("fetchedgetHmGroupListdata ", fetchedgetHmGroupListdata)

    const dispatch = useDispatch();
    useEffect(() => {

        //    dispatch(getHmGroupListdata());

        dispatch(getHmGroupListdata(tableDataForUsers.current.reqBody));
    }, [])


  

    var modifyTableHeaders =
        [
            { val: "group_name", name: "Group Name" },
            { val: "hr_managers", name: "HR managers" },
            { val: "desc", name: "Description" },
            { val: "createdon_datetime", name: "Created on", type: "datetime", format: "DD/MM/YYYY" },
            { val: "status", name: "Status" },

        ];



    let tableDataForUsers = useRef({
        tableId: "HmGroupListdata",
        tableTitle: "HM Group List",
        showReload: false,
        showFilter: false,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "manage-hm-group/hm-group-list-count",
            // deleteSingleUserEndpoint: "lead-manage/delete-lead",
            tableCountEndpoint: "manage-hm-group/hm-group-list-count",
        },

        reqBody: {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "role_list",
            secret: "AZ|lepL`",

        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [10, 20, 30],
        sortFields: ["group_name", "createdon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            {
                id: "delete",
                label: "Multi Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                modalTitle: "<p>Are you sure you want to delete this record? This can't be undone.</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "training-manage/delete-quiz",
                    body: {
                        // "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
            {
                id: "status",
                label: "Status",
                type: "api",
                category: "status",
                icon_type: "toggle_off",
                className: "status_data",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                modalTitle: "<p>Are you sure you want to change the status ?</p>",
                btn_label: ['Active', "Inactive"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "training-manage/quiz-status-change",
                    body: {
                        // "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
        ],
        buttons: [

            {
                id: "edittable9",
                type: "other",
                icon_type: "edit_icon",
                label: "Edit",
                name: "edit",
            },

            {
                id: "delete",
                label: "Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                modalTitle: "<p>Are you sure you want to delete this record? This can't be undone.</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "training-manage/delete-quiz",
                    body: {
                        // "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
        ],


        deleteModal: {
            modalClassName: "modalblock",
        },

        searchData: {},



    });


    return (
        <div>
            <div className='rolelist_mainwrp'>
                <div className='listing_heading'>
                    <h1>HM Group Management</h1>
                    <button className='addrole_btn' onClick={() => navigate('/hm-group-add')}><AddIcon /> </button>
                </div>

                <div className='rolelist_mainwrp_Table'>

                    {!loader ? (
                        <ListingTable
                            tableData={tableDataForUsers.current}
                            dataset={fetchedgetHmGroupListdata}
                            modifyHeaders={modifyTableHeaders}
                        />

                    ) : (<TableSkeleton count={5} />)}


                </div>


            </div>

        </div>
    )
}
