import React, { useEffect } from "react";
import store from "../../../store";
import { getInformationListData } from "./InformationReducer";

const Informationtemplate = () => {
  useEffect(() => {
    let reqbody = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        // "added_for": addedForId
      },
      count: false,
      source: "",
    };

    store.dispatch(getInformationListData(reqbody));
  }, []);

  return <div>Informationtemplate</div>;
};

export default Informationtemplate;
