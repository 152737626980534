import React, { useEffect, useState } from 'react'
import "../LandingPage5/LandingPage5.css"
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';
import { Form } from '../../../../form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../../store';
import { addconverstiondata, addlandingpageonedata, clearlandingpagesubmittionstate, getlandingpagedata, getlandingpageoneuserdata, updateStages, updateTags } from '../LandingpageReducer';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { fetchCalenderEvents } from '../../../Calendar/calenderReducer';
import { setLeadinfo } from '../../../login/loginReducer';
import StateArray from '../../../../assets/json/state';

function LandingPage5() {
    const [locationArry, setLocationArry] = useState([]);
    const [loading, setloading] = useState(false);


    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const [locationArry, setLocationArry] = useState([]);
    const [submissionState, setSubmissionState] = useState(0);
    // const [loading, setloading] = useState(false);
    const [loader, setLoader] = useState(false)

    const formInputData = useSelector((state) =>
        state.formSlice.formData.userForm5 ? state.formSlice.formData.userForm5 : null
    );
    const { uniquename } = useParams();
    console.log("uniquename******", uniquename);
    //  console.warn("process.env>>>>>>>",process.env);

    // console.log("loading", loading)
    let reqbodyforjobcategory = {}
    if (typeof uniquename != 'undefined') {
        reqbodyforjobcategory = {
            "uniquename": uniquename,
        }
    }
    else {
        reqbodyforjobcategory = {
            "page_slug": process.env.REACT_APP_LANDINGPAGE_SLUG,
        }
        console.warn("process.env>>>>>>>", process.env);
    }

    const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});
    const landingpageformsubmitionstate = useSelector(state => (state.LandingpagefontReducer?.landingpagesubmittionstate) ? state.LandingpagefontReducer.landingpagesubmittionstate : 0)
    const landingpageformdeta = useSelector(state => (state.formSlice?.formData?.userForm5) ? state.formSlice.formData.userForm5 : null)

    const addedData = useSelector(state => (state.LandingpagefontReducer?.addedinfo && Object.keys(state.LandingpagefontReducer?.addedinfo).length > 0) ? state.LandingpagefontReducer.addedinfo : {});

    // const fetchedStateData = useSelector(state => (state.LandingpagefontReducer?.Stagesdata) ? state.LandingpagefontReducer.Stagesdata : [])
    const fetchedStateData = useSelector(state => (state.userManagment?.FetchStages) ? state.userManagment?.FetchStages : [])


    const tagsDetails = useSelector(state => (state.userManagment?.FetchTagDetailsData) ? state.userManagment?.FetchTagDetailsData : [])
    const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)
    const [conversionapicall, setConversionapicall] = useState(false);

    console.log("fetchedStateData", fetchedStateData);


    useEffect(() => {
        if (userData && ipinfo) {
            console.log("userData", userData);
            let body = {
                // "campaign_id":userData.campaign_id,
                "associated_program": userData.associated_program,
                "landing_page_id": userData.landing_page_id,
                "landing_page_slug": userData.landing_page_slug,
                // "campaign_added_by":userData.user_id,
                // "hiring_manager":userData.hm_id,
                "ipInfo": ipinfo
            }
            if (userData.campaign_id) body['campaign_id'] = userData.campaign_id;
            if (userData.user_id) body['campaign_added_by'] = userData.user_id;
            if (userData.hm_id) body['hiring_manager'] = userData.hm_id;

            console.log("+++++++++++++++++++", body);
            if (body && body.landing_page_id && !conversionapicall) {
                dispatch(addconverstiondata(body))
                setConversionapicall(true)

            }

            //    let bodyc= Object.keys(body).forEach(key => body[key] === undefined && delete body[key])
            //    console.log("bodyc",bodyc);
        }

    }, [JSON.stringify(ipinfo), JSON.stringify(userData)])
    let currTime = new Date().getTime();
    let dayAfterTomorrow = (currTime + (48 * 3600 * 1000));
    const [slotListBody, setSlotListBody] = useState({
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "start_datetime_unix",
            "type": "desc"
        },
        "searchcondition": {
            // userid: getcookies.userInfo?._id,
            "start_datetime_unix": {
                "$gte": dayAfterTomorrow
            }
        }
        // "slot_creation_type": props.type === 'np' ? 2 : 1
    })


    useEffect(() => {
        if (uniquename) {
            store.dispatch(getlandingpageoneuserdata(reqbodyforjobcategory));
        }
        else {
            store.dispatch(getlandingpagedata(reqbodyforjobcategory));
        }
    }, [])


    useEffect(() => {
        console.log("userData", landingpageformsubmitionstate, addedData);
        if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
            dispatch(setSnackbar({ open: true, message: "Thank you for Submitting !", status: 'success' }))
            if (userData.calendar_access && userData.calendar_access == 1) {
                if (userData?.hm_id) {
                    slotListBody.searchcondition['userid'] = userData.hm_id
                    slotListBody['organiser_role'] = 'hm'
                }
                slotListBody['_id'] = addedData._id;
                resolvefunction(navigate, `/calendar/${addedData._id}/hm/${userData.hm_id}`, [
                    fetchCalenderEvents(slotListBody),
                ]);
            }
            else {
                navigate(`/thankyou`);
            }

            dispatch(setLeadinfo(addedData));
            store.dispatch(clearlandingpagesubmittionstate())





        }




    }, [landingpageformsubmitionstate, fetchedStateData])

    const [dataCopy, setDataCopy] = useState({});  //--- dataCopy  ----//

    useEffect(() => {
        console.log("addedData", addedData);

    }, [addedData])



    useEffect(() => {
        if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
            setDataCopy(addedData)
            const data = {
                stage_slug: '5QH6nS8FXNawY',
                added_by: addedData._id,
                added_for: addedData._id,
                is_delete: 0,
                status: 1
            }
            console.log("data===========================>", data)
            // return
            dispatch(updateStages(data));


            const tagdata = {
                added_by: addedData._id,
                added_for: addedData._id,
                key: "Wnv14tRS9FlmV",
                label: "hm-policiesandcompliance-signed-success",
                is_delete: 0,
                status: 1
            }
            console.log("tagdata===========================>", tagdata)
            // return
            dispatch(updateTags(tagdata));


        }


    }, [landingpageformsubmitionstate])






    useEffect(() => {
        let Newarr = [];
        if (StateArray && StateArray.length > 0) {
            for (let i in StateArray) {
                Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
            }
            setLocationArry(Newarr);
        }
    }, []);


    useEffect(() => {
        if (submissionState && submissionState === 4) {
            addapicall(formInputData);
            console.log("formInputData", formInputData, submissionState);
        }
    }, [submissionState]);

    const addapicall = async (val) => {
        console.log("val>>>", val);

        val = { ...val }
        let body = {
            "firstname": val.firstname,
            "lastname": val.lastname,
            "email": val.email,
            "phone": val.phone,
            "city": val.city,
            "state": val.state,
            "zip": val.zip,
            "streetaddress": val.streetaddress,
            "apartment": val.apartment,

            // "married": val.married,
            "campaign_id": userData.campaign_id,
            "associated_program": userData.associated_program,
            "page_id": userData.landing_page_id,
            "page_slug": userData.landing_page_slug,
            "added_by": userData.user_id,
            "hiring_manager": userData.hm_id,
            "createdon_datetime": new Date().getTime(),
            "status": 0,
            "roles": { is_rep: 1 },
            "aer_id": userData.aer_id,
            "associated_bdd": userData.associated_bdd,
            "added_type": 'landingpage'

            // is_rep: true,
        }

        if (Object.keys(body).length > 0) {
            console.log("body-----", body);

            setloading(true)
            await store.dispatch(addlandingpageonedata(body));
            // setActive(false)
            setloading(false)

        }
    };






    const formDataL2 = {
        id: 'userForm5',
        formtype: "add",
        // api_url: process.env.REACT_APP_API_URL,
        // endPoint: "lead-manage/add-update-intake-form",
        // endPointBodyWrapper: "data",
        // urlPathOnSuccessfulFormSubmission: "/calendar",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "l2submitbtnsection",
        submitBtnName: "Submit",
        // submitBtnStartIcon: 'image_search_icon',
        // submitBtnEndIcon: 'search_icon',
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
            type: "admin",
            status: "active",
        },
        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////
            {
                id: 0,
                // heading: "Full Name",
                label: "First Name",
                name: "firstname",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },
            {
                id: 1,
                // heading: "Full Name",
                label: "Last Name",
                name: "lastname",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },
            {
                id: 2,
                // heading: "Full Name",
                label: "Phone No",
                name: "phone",
                className: 'formfild',
                type: "text",
                inputType: "phone",
                rules: { required: true, minLength: 10 },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 3,
                // heading: "Full Name",
                label: "Email",
                name: "email",
                className: 'formfild',
                type: "text",
                inputType: "email",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },


            {
                id: 4,
                // heading: "Street Address",
                label: "Street Address",
                name: "streetaddress",
                className: 'formfild',
                type: "text",
                inputType: "text",/////////////// If not mentioned default will be text //////////////
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },

            {
                id: 5,
                // heading: "Street Address",
                label: "Apartment, suit, unit etc. (optional)",
                name: "apartment",
                className: 'formfild',
                type: "text",
                inputType: "text",/////////////// If not mentioned default will be text //////////////
                // rules: { required: true },
                // errorMessage: {
                //     required: "This Field is Required",
                //     custom: "Value is Invalid"
                // },
            },




            {
                id: 6,
                // heading: "Full Name",
                label: "Town / City",
                name: "city",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 7,
                // heading: "Full Name",
                label: "State",
                name: "state",
                className: 'formfild state',
                type: "select",
                // other: true,
                values: locationArry,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: "",
            },
            {
                id: 8,
                // heading: "Full Name",
                label: "Zip / Postal Code",
                name: "zip",
                className: 'formfild',
                type: "text",
                inputType: "text",
                rules: { required: true, maxLength: 5, minLength: 5 },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid",
                    maxLength: "Maxlength length 5 allowed",
                    minLength: "Minlength length 5 allowed",
                },

            },

        ]

    };

    store.subscribe(() => {
        const storeData = store.getState();
        if (storeData && storeData.formSlice && storeData.formSlice.formSubmissionState && storeData.formSlice.formSubmissionState.userForm5) {
            setSubmissionState(storeData.formSlice.formSubmissionState.userForm5);
        }
        // console.log(setSubmissionState, "setSubmissionState")
        // console.log("landing store", storeData)
    })
    return (
        <>
            <div className='landing_page5_bannerblock'>
                <div className='landing-container'>
                    <div className='landing-col-container'>
                        <div className='landing-banner-left'>
                            <div className='lpage_logowrapper'>
                                <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_logo1.webp' />
                            </div>

                            {/* <div className='l5_videowrapper Videolanding4 Videolanding3'>
                                <div className='l5_vdo_txt_wrp'>
                                    <h2>Aspire Pharmacy Savings</h2>
                                    <h5>Pay Nothing For The Most Prescribed Generic Prescriptions!</h5>
                                </div>

                                <div className='lpage_banner_sub_wrapper'>
                                    <div className='lpage_banner_sub_video'>
                                       <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing_pg5_vdosec.png'/>
                                    </div>

                                </div>


                            </div> */}
                            <div className='lpage_banner_titlewrapper'>
                                <div className='lpage_banner_sub_wrapper'>
                                    <h2><span>ASPIRE PHARMACY SAVINGS</span> <br />
                                        Pay Nothing For The Most Prescribed Generic Prescriptions!
                                    </h2>

                                    <div className='lpage_banner_textwrapper2'>
                                        <div className='lpage_banner_textwrapper1'>
                                            <p>We Make It Easy For Both You and Your Doctor</p>
                                        </div>
                                        <p>Medications can be e-prescribed, called in or faxed and can be picked up at your local pharmacy or delivered right to your home!</p>

                                    </div>

                                </div>

                            </div>
                            <div className='lpage_banner_sub_titlewrapper'>
                                <h3>
                                    We are looking for business professionals to deliver our leading-edge, cost savings solutions to companies nationwide.
                                </h3>
                            </div>
                            {/* <div className='lpage_banner_textwrapper'>
                                <p>
                                    <span>Our Friendly Pharmacist Coaches Are Here To Help</span><br />
                                    Have a question or need a prescription transferred? <br />
                                    Sit back and relax, we take care of it for you.
                                </p>
                            </div> */}
                            <div className='lpage_arrow_wrapper'>
                                <h2>Enroll today to gain access to over 605 medications for free at one low membership price!</h2>
                            </div>


                        </div>
                        <div className='landing-banner-right landingOne'>
                            <div className='formwrapper'>
                                <div className='form_title_wrapper'>
                                    <h2>
                                        <span>Fill Out The Form</span>
                                        <span>Below To Speak With One</span>
                                        <span>Of Our Team Members</span>
                                    </h2>
                                </div>
                                <div className='form_style'>
                                    <Form formData={formDataL2} />
                                    {loading ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>) : (null)}

                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <div className='lpage_banner_list_wrapper'>

                      <div className='lpage_banner_list_item'>
                          <div className='lpage_banner_list_number'>01</div>
                          <h2>No investment required</h2>
                          <div className='lpage_banner_list_icon'>
                              <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_icon1.png' />
                          </div>
                      </div>
                      <div className='lpage_banner_list_item'>
                          <div className='lpage_banner_list_number'>02</div>
                          <h2>No employees to manage</h2>
                          <div className='lpage_banner_list_icon'>
                              <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_icon2.png' />
                          </div>
                      </div>
                      <div className='lpage_banner_list_item'>
                          <div className='lpage_banner_list_number'>03</div>
                          <h2>No overhead</h2>
                          <div className='lpage_banner_list_icon'>
                              <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_icon3.png' />
                          </div>
                      </div>
                      <div className='lpage_banner_list_item'>
                          <div className='lpage_banner_list_number'>04</div>
                          <h2>No risk</h2>
                          <div className='lpage_banner_list_icon'>
                              <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage/images/l_page1_banner_icon4.png' />
                          </div>
                      </div> 

                  </div> */}

                </div>

            </div>
            <div className='landingpage5_bannerblock2'>
                <div className='landing-container'>
                    <div className='landing-col-container2'>
                        {/* <div className='landingpage5_bannerblock2_left_section'>
                    
                    </div> */}
                        <div className='landingpage5_bannerblock2_right_section'>
                            <h2>We are committed to improving your life <br />
                                and saving you money.</h2>
                            <h3><span>We are turning heads as we release America’s <br />
                                First Free Pharmacy.</span></h3>
                            <p>This pharmacy program has been designed to make the most used, generic medications<span> available for FREE to everyone</span>. These drugs are typically used to treat conditions like Diabetes, Heart Disease, High Cholesterol, and Depression.</p>

                            <p>  We have assembled a team of pharmacy professionals with experience in all aspects of the industry. Our expertise resides in medication pricing, sourcing, distribution, strategy and marketing. With decades of real world knowledge, we set out to build the most efficient and cost effective pharmacy in the United States. We have the most high-tech software, hardware and processing system around and utilize its capabilities to deliver medications<span> within 1-3 days right to your door for prices you won’t find anywhere else.</span>
                            </p>
                            <div className='landingpage5_bannerblock2_right_subsection'>
                                <label>Enroll today to gain access to your free medication!</label>
                            </div>


                        </div>


                    </div>
                </div>

            </div>

            <div className='landingpage5_bannerblock3'>

                <div className='landing-container'>
                    <div className='landingpage5_bannerblock3_subwrapper'>
                        <div className='landing-col-container3'>
                            <div className='landingpage5_bannerblock3_firstblock'>
                                <p>We offer the 605 most prescribed drugs in America for free!</p>

                            </div>
                            <div className='landingpage5_bannerblock3_secondblock'>
                                <div className='landingpage5_bannerblock3_secondblock_leftsection'>
                                    <p><span>125</span> Generic Acute <br />
                                        Medications<strong> (Immediate Need)</strong> </p>

                                </div>
                                <div className='landingpage5_bannerblock3_secondblock_rightsection'>
                                    <p><span>480</span> Generic Chronic <br />
                                        Medications<strong> (Maintenance)</strong></p>

                                </div>
                            </div>
                            <div className='landingpage5_bannerblock3_thirdblock'>
                                <h2>Here Are Just A Few From Our List</h2>
                                <div className="hr"></div>
                                <ul>
                                    <li>ACYCLOVIR</li>
                                    <li>ALLOPURINOL</li>
                                    <li>AMITRITYLINE</li>
                                    <li>AMLODIPINE</li>
                                    <li>AMOXICILLIN</li>
                                    <li>ANASTRAZOLE</li>
                                    <li>ATENOLOL</li>
                                    <li>ATORVASTATIN</li>
                                    <li>AZITHROMYCIN</li>
                                    <li>AMLODIPINE</li>
                                    <li>BUPROPION</li>
                                    <li>CARVEDILOL</li>
                                    <li>CEFDINIR</li>
                                    <li>CELECOXIB</li>
                                    <li>CEPHALEXIN</li>
                                    <li>CIPROFLOXACIN</li>
                                    <li>CITALOPRAM</li>
                                    <li>CLOPIDOGREL</li>
                                    <li>CYCLOBENZAPRINE</li>
                                    <li>DICLOFENAC</li>
                                    <li>DILTIAZEM</li>
                                    <li>DOXYCYCLINE</li>
                                    <li>DULOXETINE</li>
                                    <li>ESCITALOPRAM</li>
                                    <li>ESTRADIOL</li>
                                    <li>EZETIMIBE</li>
                                    <li>FENOFIBRATE</li>
                                    <li>FINASTERIDE</li>
                                    <li>FLUCONAZOLE</li>
                                    <li>FLUOXETINE</li>
                                    <li>GLIMEPIRIDE</li>
                                    <li>GLIPIZIDE</li>
                                    <li>HYDRALAZINE</li>
                                    <li>IBUPROFEN</li>
                                    <li>LAMOTRIGINE</li>
                                    <li>LEVOTHYROXINE</li>
                                    <li>LIOTHYRONINE</li>
                                    <li>LISINOPRIL</li>
                                    <li>LISINOPRIL/HCTZ</li>
                                    <li>LOSARTAN</li>
                                    <li>MELOXICAM</li>
                                    <li>METFORMIN</li>
                                    <li>METHOTREXATE</li>
                                    <li>METOPROLOL</li>
                                    <li>MONTELUKAST</li>
                                    <li>OMEPRAZOLE</li>
                                    <li>PANTOPRAZOLE</li>
                                    <li>PAROXETINE</li>
                                    <li>PRAVASTATIN</li>
                                    <li>PROGESTERONE</li>
                                    <li>ROSUVASTATIN</li>
                                    <li>SERTRALINE</li>
                                    <li>SIMVASTATIN</li>
                                    <li>SILDENAFIL</li>
                                    <li>TAMSULOSIN</li>
                                    <li>TRAMADOL</li>
                                    <li>TRAZODONE</li>
                                    <li>TRIAMCINOLONE</li>
                                    <li>VALACYCLOVIR</li>
                                    <li>VENLAFAXINE</li>

                                </ul>

                            </div>




                        </div>


                    </div>
                    <div className='landingpage5_bannerblock3_fourblock'>
                        <h2>Enroll today to gain access to your free medication!</h2>

                    </div>

                </div>
            </div>
            <div className='landingpage5_bannerblock4'>
                <div className='landing-container'>
                    <div className='landing-col-container4'>
                        <h2>Our Pharmacy Coaching <br />
                            Team Is Second-To-None! Call Us Today!</h2>
                        <p><span>We take the stress off of you and take care of everything, simply<br /><strong> call 800.863.8926</strong> and sit back and relax knowing our team is on<br /> it. This is auto-pilot for you and your doctor.</span></p>


                    </div>


                </div>

            </div>
            <div className='landingpage5_bannerblock5'>
                <div className='landing-container'>
                    <div className='landingpage5_bannerblock5_firstblock'>
                        <h3>LAUREN KALVIN</h3>

                        <p> <span><img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock5_1logo.png' ></img></span>The prices on prescriptions for my family were going up every year, and I couldn't keep paying the high prices, so I searched for a better option. I came across Access Discount Healthcare Rx and I won't buy them any other way now. With Access Discount Healthcare Rx, I pay nothing for our medication!.<span><img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock5_2logo.png'></img></span></p>

                    </div>
                </div>
            </div>
            <div className='landingpage5_bannerblock6'>
                <div className='landing-container'>
                    <div className='landingpage5_bannerblock6_mainwrapper'>
                        <h2>ACCEPTED AT 64,000 PHARMACIES NATIONWIDE INCLUDING:</h2>
                        <div className='landingpage5_bannerblock6_firstblock'>
                            <div className='landingpage5_bannerblock6_sub_firstblock'>
                                <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock6_firstsubblock_1logonew.png'></img>
                                <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock6_firstsubblock_2logonew.png'></img>
                                <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock6_firstsubblock_3logonew.png'></img>
                                <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock6_firstsubblock_4logonew.png'></img>
                                <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock6_firstsubblock_5logonew.png'></img>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div className='landingpage5_bannerblock7'>
                <div className='landing-container'>
                    <div className='landingpage5_bannerblock7_mainwrapper'>
                        <h2>Everything you need to know<br />
                            about the program</h2>
                        <h3><span>Stop Paying For Prescriptions…It’s Easy!</span></h3>
                        <p>At Access Discount<span> Healthcare Rx we have made</span> it our mission to help the uninsured and underinsured (people who have insurance but deductibles are so high they never meet them and they are paying full price for medicine). With decades or relationships, partnerships and buying power, we have pooled our resources and are bringing you the lowest prices on medications in the country.</p>

                    </div>
                </div>
            </div>
            <div className='landingpage5_bannerblock8'>
                <div className='landing-container'>
                    <div className='landingpage5_bannerblock8_mainwrapper'>
                        <h2>Simply Follow These 6 Steps To Free Medication</h2>
                        <div className='landingpage5_bannerblock8_sub_mainwrapper'>
                            <div className='landingpage5_bannerblock8_step1'>
                                <div className='landingpage5_bannerblock8_step1_img'>
                                    <h4>STEP 1</h4>
                                    <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_1step_img.png'></img></div>
                                <div className='landingpage5_bannerblock8_step1_text'>
                                    <h3>SIGN-UP FOR YOUR MONTHLY MEMBERSHIP</h3>
                                    <p>As A Member In Our Program You Get Access To <br />
                                        All Of Our Medications For<span> FREE</span>!</p></div>

                            </div>
                            <div className='landingpage5_bannerblock8_step2'>
                                <div className='landingpage5_bannerblock8_step2_img'>
                                    <h4>STEP 2</h4>
                                    <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_2step_img.png'></img>
                                </div>
                                <div className='landingpage5_bannerblock8_step2_text'>
                                    <h3>SEARCH OUR LIST OF 605 GENERIC MEDICATIONS</h3>
                                    <p>Identify The Medications You Are Taking. Compare<br />
                                        What You Paid For Them To <span>FREE With Us</span>!</p>

                                </div>

                            </div>
                            <div className='landingpage5_bannerblock8_step3'>
                                <div className='landingpage5_bannerblock8_step3_img'>
                                    <h4>STEP 3</h4>
                                    <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_3step_img.png'></img>
                                </div>
                                <div className='landingpage5_bannerblock8_step3_text'>
                                    <h3>CALL OUR PHARMACY COACHES TO REVIEW <br />
                                        YOUR MEDICATIONS</h3>
                                    <p>Our Team Is Here To Help. We Will Make Sure Your <br />
                                        Medications Are On The List Or<span> Find Therapeutically <br />
                                            Similar Ones</span></p>
                                </div>

                            </div>
                            <div className='landingpage5_bannerblock8_step4'>
                                <div className='landingpage5_bannerblock8_step4_img'>
                                    <h4>STEP 4</h4>
                                    <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_4step_img.png'></img>

                                </div>
                                <div className='landingpage5_bannerblock8_step4_text'>
                                    <h3>TRANSFER YOUR PRESCRIPTIONS OR YOUR DOCTOR<br />
                                        CAN E-PRESCRIBE TO US</h3>
                                    <p>Transferring Prescriptions Can Be Difficult, So Our Pharmacy<br />
                                        Team Does It <span>ALL For You</span> !</p>

                                </div>

                            </div>
                            <div className='landingpage5_bannerblock8_step5'>
                                <div className='landingpage5_bannerblock8_step5_img'>
                                    <h4>STEP 5</h4>
                                    <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_5step_img.png'></img>

                                </div>
                                <div className='landingpage5_bannerblock8_step5_text'>
                                    <h3>WE WILL CONTACT YOU TO CONFIRM ORDER AND<br />
                                        COLLECT PAYMENT</h3>
                                    <p>We Always Make Sure Everything Is Accurate. We Double<br />
                                        Check The Prescription And <span>Securely Place Your Order</span></p>

                                </div>

                            </div>
                            <div className='landingpage5_bannerblock8_step6'>
                                <div className='landingpage5_bannerblock8_step6_img'>
                                    <h4>STEP 6</h4>
                                    <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landingpage5_bannerblock8_6step_img.png'></img>

                                </div>
                                <div className='landingpage5_bannerblock8_step6_text'>
                                    <h3>RECEIVE YOUR MEDICATIONS</h3>
                                    <p>You Can Pick-up Acute Medications at your Local<br />
                                        Pharmacy or Have Chronic Medications Shipped To <br />
                                        Your Home For <span>FREE</span>!</p>

                                </div>

                            </div>
                        </div>
                        <div className='bluebgtitle'>
                            <p>Enroll today to gain access to your free medication!</p>

                        </div>


                    </div>
                </div>
            </div>
            <div className='landingpage5_bannerblock9'>

                <div className='landingpage5_bannerblock9_mainwrapper'>
                    <div className='landingpage5_bannerblock9_subwrapper1'>
                        <div className='landing-container'>
                            <div className='landingpage5_bannerblock9_mainwrapper_firstpart'>
                                <h2>Frequently asked questions</h2>
                                <h3><span>What’s the difference between a brand-name drug and <br />
                                    a generic equivalent drug?</span></h3>
                                <p>According to the Food and Drug Administration, generic drugs are the same as their brand-name equivalents in active ingredients, dosage, safety, strength, and performance, and are held to the same strict standards as their brand-name counterparts. The only noticeable difference between a generic drug and its brand-name counterpart may be the shape and/or color of the drug.<span> While generic drugs are just as effective as the corresponding brand-name drugs, they typically cost up to 70 percent less,</span> which helps control health care costs. This helps you in the long run because the generic option is always the lowest cost to you.</p>
                            </div>
                        </div>
                    </div>
                    <div className='landingpage5_bannerblock9_subwrapper2'>
                        <div className='landing-container'>
                            <div className='landingpage5_bannerblock9_mainwrapper_secondpart'>
                                <h2>What if my drug is not available in generic form?</h2>
                                <p>Even if your drug is not yet available in generic form, there may be a very similar generic drug that can be just as effective. If you are currently taking a brand-name medication, ask your doctor about if there is a generic equivalent drug that could treat your condition just as well.<span> In most cases brand-name drugs are protected under a 10-17 year patent</span>, and a generic equivalent cannot be made. During this period of time, manufacturers control pricing to make up for expensive research and development. In the interests of saving time, money and taking care of patients, most doctors will consider substituting a generic therapeutic alternative. A therapeutic similar is actually the generic match for the brand-name drug and that treats the same condition using a different ingredients. In most cases, these alternatives are just as effective.</p>

                            </div>
                        </div>
                    </div>



                </div>
            </div>


            {/* 
            <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landing-page5_Demo1.webp' className='demo5img'></img>

            <div className='demo5Con'><img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landing-page5_Demo2.webp' ></img>
                <div className='bluebgtitle'>
                    <p>Enroll today to gain access to your free medication!</p>
                </div>

            </div>


            <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/landing-page5_Demo3.webp' className='demo5img'></img> */}



            <div className='landingpage5_block12'>
                <div className='landing-container'>
                    <div className='landingpage5_block12_subwrapper'>
                        <div className='landingpage5textwrapper'>

                            <div className='bluebgtitle'>
                                <p>What should I ask my doctor?</p>
                            </div>
                            <p className='pr'>First, make sure your doctor knows you have a new pharmacy program and that you get any of the 605 generic medications for FREE. Then, as “would one of these generic drugs be a good option for me, and could it treat my condition in the same way?” You can also ask what other brand­ name drugs that you are taking that could be replaced with generics options on this list. Remember, most medications Americans take are generic.
                            </p>

                            <div className='bluebgtitle'>
                                <p>Why aren’t ALL medicines on your drug list?</p>
                            </div>
                            <p>Generic medications make up for 85% of the medications dispensed in America each year. Our list accounts for 90% of medications prescribed. Our list has medications grouped in more than 22 therapeutic classes making sure that we have a very well-rounded formulary treating a variety of chronic and acute conditions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='landingpage5_lstblock'>
                <div className='landing-container'>
                    <div className='landingpage5_lstblock_subwrapper'>

                        <div className='landingpage5textwrapper'>
                            <h1>Members Can Get A <span> 90-Day </span> Prescription For Literally  Zero Dollars, Shipped To Your Home For FREE! </h1>

                            <div className='bluebgtitle'>
                                <p>Enroll today to gain access to your free medication!</p>
                            </div>
                        </div>
                        <img src='https://all-frontend-assets.s3.amazonaws.com/landing-page5/lpage5lst1image1.webp' className='abimage' />

                    </div>
                </div>
            </div>

        </>
    )
}

export default LandingPage5