import React from 'react'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { Chip, IconButton, LinearProgress, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { format } from 'date-fns'


const StageLifeCycle = ({LifeCycleDataStage}) => {
  const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)

    console.log("StageLifeCycleData",LifeCycleDataStage);
  return (
    <div className='blocks'>
        <h3>
            <PlaylistPlayIcon /> Stage Life Cycle {" "}
            <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
              
              <Tooltip title="Search Email">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Search Email">
                <IconButton>
                  <AddIcon />
                </IconButton>
              </Tooltip> */}
              
            </div>
          </h3>
          {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}


        {LifeCycleDataStage.length > 0 ?
          LifeCycleDataStage.map((x) => (
            <span className='stageChipB'>
                            <Chip label={x.stage_name} sx={{ margin: '10px' }} variant="outlined" />
                             <QuestionMarkIcon className='qIcon' />

                          
                            <span className='chipDesBuble'>
                                {x.stage_name} <br />
                                Added by: {x.added_by_name} On {format(x.createdon_datetime, 'dd-MM-yyyy HH:mm')}
                            </span> 
            </span>
          )):
          (<p> No stage assigned!</p>)}  




    </div>
  )
}

export default StageLifeCycle