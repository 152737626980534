import { Chip, IconButton, LinearProgress, Tooltip } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import SegmentIcon from "@mui/icons-material/Segment";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const TagLifeCycle = ({ LifeCycleDataTag }) => {
  const loading = useSelector((state) =>
    state?.userFolderViewReducer?.loading
      ? state.userFolderViewReducer.loading
      : false
  );

  console.log("TagLifeCycle", LifeCycleDataTag);
  return (
    <>
      <div className="blocks">
        <h3>
          <SegmentIcon /> Tag Life Cycle{" "}
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title="Search Email">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Search Email">
                <IconButton>
                  <AddIcon />
                </IconButton>
              </Tooltip> */}
          </div>
        </h3>
        {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}

        {LifeCycleDataTag.length > 0 ? (
          <div>
            { 
              LifeCycleDataTag?.map((x) => (
                <ul className="Fv-tags">
                  <li className="tagsBlck">
                    <Chip label={x.tag_name} variant="outlined" />
                    <QuestionMarkIcon className="qIcon" />
                    {/* <p className="chipDesBuble">
                      {x.tag_description} <br />
                      Added by : {x.added_by_name} On{" "}
                      {format(x.createdon_datetime, "dd-MM-yyyy HH:mm")}
                    </p> */}
                  </li>
                </ul>
              ))
            }
          </div>
        ) : (
          <p> No tag assigned!</p>
        )}
      </div>








    </>
  );
};

export default TagLifeCycle;
