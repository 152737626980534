import { Button, Drawer, IconButton, LinearProgress, Modal } from '@mui/material'
import React, { useEffect } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from '@mui/system';
import CloseIcon from "@mui/icons-material/Close";
import { reSetSubmistionstate, sendMailForUser } from './LeadReducer';
import store from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../Layout/layoutReducer';
import { clearEmailSlagedata } from '../EmailTemplateManagement/EmailTemplateReducer';



const EmailSendDrower = ({ close, open, data, loading, addedById, addedForId, submistionstate }) => {
    const dispatch = useDispatch(); // ---- Dispatch -----

    const [openMoadl, setOpenMoadl] = React.useState(false);
    const [moadlData, setMoadlData] = React.useState(null);
    // let submistionstate = useSelector(state => state.LeadReducer?.submistionstate);
    // console.log("submistionstate11",submistionstate);






    useEffect(() => {

        console.log("submistionstate", submistionstate);
        if (submistionstate === 2) {
            setOpenMoadl(false)
            store.dispatch(
                setSnackbar({ open: true, message: "Sucessfully send mail", status: "sucess" })
            )

        }

        return () => {
            store.dispatch(reSetSubmistionstate())
        }


    }, [submistionstate]);


    const openModal = (data) => {
        setOpenMoadl(true)
        setMoadlData(data)
    };
    const closeModal = () => {
        setOpenMoadl(false)
    };

    const sendEmail = (id) => {
        let body = {
            "added_for": addedForId,
            "added_by": addedById,
            "template_id": id
        }


        store.dispatch(sendMailForUser(body))
    };


    return (
        <>

            <Drawer anchor="bottom" open={open} className="contractdrawer">
                {/* <Drawer anchor="bottom"  className="contractdrawer"> */}

                <div className="drawer-content">
                    <div className="heading">
                        <h2> CHOOSE EMAIL</h2>
                    </div>

                    {/* -- if we login with Admin then this block will appear */}

                    <div className="btn-grp">

                        {
                            //   props.contractdata.name? (<li> </li>):""
                            data.map((data) => <Button variant="contained" className="conts" id={data._id} onClick={() => openModal(data)}>{data.subject}</Button>)
                        }


                    </div>

                    <CancelIcon onClick={()=>{close(); dispatch(clearEmailSlagedata())}} className="drawerCloseIcon" />

                </div>
            </Drawer>



            <div>
                <Modal
                    classname="image_card_modal modalBaseStyle"
                    open={openMoadl}
                    onClose={closeModal}
                    closeAfterTransition
                >
                    <Box className='modalBaseBox largeModal'>
                        <IconButton className="modal_icon_button" onClick={closeModal}>
                            <CloseIcon className="modal_close_icon" />
                        </IconButton>
                        {loading && <LinearProgress />}

                        <div className="heading">
                            <h2>Email Template</h2>
                        </div>

                        <div className='cont-body' dangerouslySetInnerHTML={{ __html: moadlData?.template_html }} />

                        <div className="btn-grp">

                            <Button variant="contained" onClick={() => sendEmail(moadlData._id)}> SEND EMAIL</Button>

                        </div>

                    </Box>
                </Modal>
            </div>


        </>
    )
}

export default EmailSendDrower