import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Grid, IconButton, LinearProgress, Snackbar, TextField, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";
import StateArray from '../../../../assets/json/state';
import fetchUserDataReducer, { fetchUserData } from '../fetchUserDataReducer';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { UserActivity } from './folderViewReducer';
import { FetchRoles } from '../userList/userListReducer';
import Interview from './Interview';
import LoginIcon from '@mui/icons-material/Login';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import FolderIcon from '@mui/icons-material/Folder';
import LogoutIcon from '@mui/icons-material/Logout';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SearchIcon from '@mui/icons-material/Search';

const UserActivityBlock = (props) => {
    // const [users, setusers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 

    // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

    // ---- States here -----
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
    const [open, setOpen] = React.useState(false); // snackbar const
    const [value, setValue] = React.useState([null, null]);



    // ---- geeting and setting QueryParam -----
    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [JSON.stringify(paramId)])

    // -------------------------------


    // ---- Cookies here ----
    const [cookieData, setCookieData] = useCookies();
    // console.warn("<<<<< cookieData from User folderView >>>>>", cookieData)


    // --- loader --- 
    const loader = useSelector(state => (state.fetchUserDataReducer?.loading
    ) ? state.fetchUserDataReducer.loading
        : null);

    const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userFolderViewForm) ? state.formSlice.formSubmissionState.userFolderViewForm : false);

    const loading = useSelector(state => (state?.userFolderViewReducer?.waiting) ? state.userFolderViewReducer.waiting : false)

    const UserActivityData = useSelector(state => (state?.userFolderViewReducer?.userActivityList) ? state?.userFolderViewReducer?.userActivityList : [])


    // console.warn("<<<<<<<<<< folderdata goes here >>>>>>>>>", folderdata)



    // snackbar Close
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        // setErrPopup(false);
        // setErrMsgCopy(null);
    };



    return (
      <>
        <div>
          <h3>
            <PersonIcon /> {props?.name} Activity
            <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
              <Tooltip title="Search Email">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </div>
            {/* <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        localeText={{ start: 'Check-in', end: 'Check-out' }}
                    >
                        <DateRangePicker
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider> */}
          </h3>
          {loading ? (
            <Box sx={{ width: "100%", marginBottom: "10px" }}>
              <LinearProgress />
            </Box>
          ) : null}

          <div className="UA-scroll-activity">
            {props.activity?.length > 0 ? (
              props.activity?.map((x) => (
                <div className="user-activity">
                  <ul className="ua-data-block">
                    <li className="heading">
                      {" "}
                      {x.activity_type == "login" ? (
                        <LoginIcon />
                      ) : x.activity_type == "tag_assigned" ? (
                        <LocalOfferIcon />
                      ) : x.activity_type == "folder_accessed" ||
                        x.activity_type == "folder_accessed_by" ? (
                        <FolderIcon />
                      ) : x.activity_type == "email_sent" ||
                        x.activity_type == "email_sent_by" ? (
                        <MarkEmailReadIcon />
                      ) : x.activity_type == "Log out complete" ? (
                        <LogoutIcon />
                      ) : x.activity_type == "stage_assigned_by" ||
                        x.activity_type == "stage_assigned" ? (
                        <ClearAllIcon />
                      ) : x.activity_type == "Get Contract" ? (
                        <TextSnippetIcon />
                      ) : (
                        " "
                      )}{" "}
                      {x.activity_type ? x.activity_type : "N/A"}{" "}
                    </li>
                    <li>
                      Activity Time:{" "}
                      {format(x.activity_time, "dd-MM-yyyy HH:mm:ss")}{" "}
                    </li>
                    <li>Activity: {x.activity}</li>
                  </ul>
                </div>
              ))
            ) : (
              <p> No activity found!</p>
            )}
          </div>
        </div>
      </>
    );
}

export default UserActivityBlock;






