
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, IconButton, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, TextareaAutosize, TextField, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles, clearContractModalData, sendContract, upgradeRole } from './userListReducer';
import SnackBarComp from '../../../../commoncomponents/snackbar/snackBar';



const ContractViewModal = (props) => {



    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(false);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
    const [userRoles, setUserRoles] = React.useState([]);
    const [copyUserRoles, setcopyUserRoles] = React.useState([]);
    const [open, setopen] = React.useState(false);
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [snackFire, setSnackFire] = useState(false);  //--- Open SnackBar ----//
    const [resMessage, setResMessage] = useState(null);  //--- resMessage  ----//
    const [endpointResStatus, setEndpointResStatus] = useState(null);  //--- resMessage  ----//
    const [notes, setNotes] = useState('');  //--- resMessage  ----//
    const [contractSendSucess, setContractSendSucess] = useState(false);  //--- contractSendSucess  ----//



    console.warn("<<<<<< abcd ----------------------props ContractViewModal goes here >>>>>>", props)
    console.warn("<<<<<< cookies goes here >>>>>", userInfo)
    // console.warn("<<<<<< contractdata goes here >>>>>", props.contractdata)
    // console.warn("<<<<<< userinfo goes here  ######### 23424234243 >>>>>", props.userinfo)



    // ---- for loader -----
    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)
    const contractSend = useSelector(state => (state.userManagment?.contractSend) ? state.userManagment?.contractSend : false)


    useEffect(() => {
        setContractSendSucess(false)
        props.tooglefunc(true);

    }, [])


    useEffect(() => {
        if (contractSend) {
            setContractSendSucess(contractSend)
        }
    }, [contractSend])


    // ------ on contractSendSucess ---
    useEffect(() => {
        if (contractSendSucess) {
            setSnackFire(true)
            setResMessage("Contract Send Sucessfull!")
            setEndpointResStatus("success")

            setTimeout(() => {
                props.tooglefunc(false);
                dispatch(clearContractModalData())
            }, 3000);
        }
    }, [contractSendSucess])
    // ---------------------------------


    function sendContracts() {
        console.warn("<<<<<<<<<< sendContract >>>>>>>>>")

        const payload = {
            "data": {
                "user_id":    props.userinfo?._id,
                "contract_id": props?.contractdata?.contract_id,
                "send_by": userInfo?.userinfo?.name,
                "user_name": props.source=="lead"?props.userinfo?.fullname:props.userinfo?.name,
                "email": props.source=="lead"?props.userinfo?.email:props.userinfo?.o_email,
                "notes": notes,
                "send_by_id": userInfo?.userinfo?._id,
                // "contract_name":
                "link": process.env.REACT_APP_DOMAIN_URL+"viewcontract"

            }
        }

        dispatch(sendContract(payload))


    }



    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.tooglefunc(false);
        // setOpenDropDown(false)
    }


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock contract_modal"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    className={"BoxMui_modal other_BoxMui_modal"}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        // p: 4,
                        textAlign: "center",
                    }}
                >


                    <div className="heading">
                        <h2> Contact Details</h2>
                    </div>

                    <div className='cont-body' dangerouslySetInnerHTML={{ __html: props.contractdata.modified_html }} />

                    {/* </div> */}


                    {/* {props.contractdata} */}


                    <Tooltip title="Close" placement="right-start">
                        <IconButton className="modalCloseBtn" onClick={ModalClose}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>




                    <div className="btn-grp">

                        <div className="notesblock">
                            <TextareaAutosize id="outlined-basic"
                                name='notes'
                                onInput={e => setNotes(e.target.value)}
                                className='inputBlock notesinput'
                                type="text"
                                label="Notes"
                                variant="outlined"
                                placeholder="Add Notes"
                            // style={{resize: "vertical", overflow: 'auto', overflowY:'scroll'}}
                            // defaultValue={props?.data?.priority} 
                            />
                        </div>

                        <Button variant="contained" onClick={() => sendContracts()}> SEND CONTRACT</Button>
                    </div>

                </Box>

            </Modal>



            {/* --------- SnackBar -------- */}
            {snackFire != false && resMessage !== null ? <SnackBarComp
                setSnackFire={setSnackFire}
                resMessage={resMessage}
                snackFire={snackFire}
                endpointResStatus={endpointResStatus}
                userinfo={props.userinfo}
            /> : ""}
            {/* ------------ */}

        </>
    )
}


export default ContractViewModal;



