import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const getHmGroupListdata = createAsyncThunk("getHmGroupListdata", async(reqbody) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-hm-group/hm-group-list-count", requestOptions);
    console.log('response',response)
    const FetchStages = await response.json();

    return FetchStages;
});




const HmGroupReducer = createSlice({

    name: 'hmgroupreducer',

    initialState: {
        submitting: false,
        loading: false,
        hmGroupListdata:[]
    },

    reducers: {
        clearHmGroupListdata(state, action) {
            state.hmGroupListdata = []
        },

    },

    extraReducers: {

        // ================ For GET DATA ================ //
        [getHmGroupListdata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getHmGroupListdata.pending]: (state, action) => {
            state.loading = true;
        },
        [getHmGroupListdata.fulfilled]: (state, action) => {

            // console.log(state,'state============')
            

            if (action.payload.status === 'success') {
                console.log(action.payload.status, 'getHmGroupListdata action============')
                state.hmGroupListdata = action.payload.results.res;
                console.log('hmGroupListdata', state.hmGroupListdata)
                 
                state.loading = false;
            }
        },

    }



})


export default HmGroupReducer.reducer;
export const { clearHmGroupListdata} = HmGroupReducer.actions;