import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { Cookies, useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { timeConverter } from '../../helper/helperFunctions';
import store from '../../store';
import { getLeadInfo, getLeadInfoInThankYou } from '../login/loginReducer';

// import './thankyou.css';



export const ThankyouPage = () => {
    const cookies = new Cookies();
    const getcookies = cookies.getAll();

    const [cookieSlotData, setCookieSlotData] = useCookies(["SlotData"]);
    const navigate = useNavigate();
    const [loaderFlag, setloaderFlag] = useState(true);
    const params = useParams();
    const dispatch = useDispatch();
    const [paramID, setParamID] = useState(null);
    const [isLeadInfoBlank, setIsLeadInfoBlank] = useState(false);
    const [attendeeData, setAttendeeData] = useState([]);
    // const [cookieCancelData, setCookieCancelData] = useCookies(["cancelData"]);
    const page = params.page;
    const abbrs = {
        HST: 'Hawaii Standard Time',
        EDT: 'Eastern Daylight Time',
        EST: 'Eastern Standard Time',
        CST: 'Central Standard Time',
        CDT: 'Central Daylight Time',
        MST: 'Mountain Standard Time',
        MDT: 'Mountain Daylight Time',
        PST: 'Pacific Standard Time',
        PDT: 'Pacific Daylight Time',
        IST: 'Indian Standard Time',
        AKDT: 'Alaska Standard Time'
    };

    useEffect(() => {
        if (params && params.lead_id) {
            setParamID(params.lead_id);
        };
    }, [params]);
    useEffect(() => {
        if (!isLeadInfoBlank) {
            let data = {
                id: params.lead_id
            }
            dispatch(getLeadInfoInThankYou(data));
        }
    }, [isLeadInfoBlank])

    store.subscribe(() => {
        const storeData = store.getState();
        if (storeData.loginSlice.leadInfo) {
            setAttendeeData(storeData.loginSlice.leadInfo)
            if (Object.keys(storeData.loginSlice.leadInfo).length > 0 && !storeData.loginSlice.loading) {
                console.log("page storeData", storeData.loginSlice)
                setloaderFlag(false)
                setIsLeadInfoBlank(true);
            }
        }
    })


    //#########################################################################

    const backToDashboard = () => {
        navigate('/dashboard');
    }

    console.log("page attendeeData params", attendeeData)

    return (
        <>
            {/* <div className="BackendBody"> */}
            <div className='thankYou_wrpr'>

            <div className="bgdesgn1"></div>
            <div className="bgdesgn2"></div>
            <div className="bgdesgn3"></div>
            <div className="bgdesgn4"></div>
            <div className="bgdesgn5"></div>
            <div className="bgdesgn6"></div>
            <div className="bgdesgn7"></div>
            <div className="bgdesgn8"></div>


                {loaderFlag ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>)
                    : (
                        ""
                    )}
                <div className='thankyou-container'>
                    <div className='thankyou-block'>

                        <div className='content'>

                            {/* <h1>Your Slot {page === "booking" ? 'Booked' : page === "reschedule" ? "Re-Scheduled" : "Canceled"} Successfully!</h1> */}
                            <h1>{page === "booking" ?
                                "Thank you for scheduling a meeting with Aspire Lead Fire CRM" :
                                page === "reschedule" ?
                                    "Thank you for re-scheduling a meeting to discuss our government releif and expense reduction programs for your business contacts and executives" :
                                    "Your scheduling appointment has been cancelled successfully. We're really sorry to see you go !"}</h1>
                            {/* <p>User Name : {attendeeData.username}</p> */}
                            <p> <strong>Event Title : </strong> <span> {!loaderFlag && attendeeData && attendeeData.event_title ? attendeeData.event_title : ''} </span> </p>
                            {/* <p>Organizer E-mail : {attendeeData.bookingUserEmail} </p> */}
                            <p> <strong>Appointment Booked Date :</strong> <span>{!loaderFlag && attendeeData && attendeeData.slot_start_time_unix ? timeConverter({ time: new Date(attendeeData.slot_start_time_unix), format: "L", timezone: attendeeData.user_timezone }) : ''} </span>  </p>
                            <p>  <strong>Start Time : </strong> <span> {!loaderFlag && attendeeData && attendeeData.slot_start_time_unix ? timeConverter({ time: new Date(attendeeData.slot_start_time_unix), format: "h:mm A", timezone: attendeeData.user_timezone }) : ''}</span> </p>
                            <p>  <strong>End Time : </strong> <span>{!loaderFlag && attendeeData && attendeeData.slot_end_time_unix ? timeConverter({ time: new Date(attendeeData.slot_end_time_unix), format: "h:mm A", timezone: attendeeData.user_timezone }) : ''}</span>  </p>
                            <p> <strong>Appointment Duration : </strong> <span>{!loaderFlag && attendeeData && attendeeData.timespan ? attendeeData.timespan : ''} mins</span> </p>
                            <p> <strong>Timezone :</strong> <span>{!loaderFlag && attendeeData && attendeeData.user_timezone ? attendeeData.user_timezone : ''}</span>  </p>

                            {attendeeData.type && attendeeData.type === "np" ?
                                <button onClick={backToDashboard}>Back to Dashboard</button>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}

        </>
    )
}

